import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";
import { Formik } from "formik";
import FormLabel from "@mui/material/FormLabel";
import { RollerAnimation } from "../loader/Loader";
import LabelIcon from "@mui/icons-material/Label";
import Slider, { SliderTooltip } from "rc-slider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import {
  crypto_options,
  email_options,
  card_type_options,
  exp_month_options,
  exp_year_options,
} from "../../../utils/Globals/global_variables";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import axios from "axios";
import Categories from "../sections/Checkbox/Categories";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
const { Handle } = Slider;
function UpdateAdminAlertFilters({
  modal_data,
  set_modal_data,
  keyword_modal,
  setKeyword_modal,
  postCategory_modal,
  setPostCategory_modal,
  interval_type,
  set_interval_type,
  interval_number,
  set_interval_number,
  notification_filters_modal,
  set_notificaton_filters_modal,
  alert_type_modal,
  set_alert_type_modal,
  post_data_modal,
  set_open_translate_modal,
  // language_array_modal,
  // set_language_array_modal,
  // checked_exclude_modal,
  // set_checked_exclude_modal
}) {
  const token = handle_session_get("Token");
  const [search_val_modal, set_search_val_modal] = useState("");
  const [search_data_modal, set_search_data_modal] = useState(null);
  const [stat_loading, set_stat_loading] = useState(false);
  const [search_error, set_search_error] = useState(null);
  const [channel_name_clicked, set_channel_name_clicked] = useState("");

  const [show_tag_list_div, set_show_tag_list_div] = useState(false);

  const tag_name_list = null;
  useEffect(() => {
    if (modal_data) {
      if (
        modal_data.notification_type === "channel" &&
        modal_data.notification_filter === "Default"
      ) {
        try {
          if (modal_data.keyword_entered.includes("https://t.me/s") === false) {
            set_channel_name_clicked(modal_data.keyword_entered);
          }
        } catch {
          //Do nothing
        }
      }
    }
  }, [modal_data]);

  const add_channels_list = (item) => {
    if (modal_data.channel_name.includes(item) === false) {
      set_modal_data({
        ...modal_data,
        channel_name: [...modal_data.channel_name, item],
      });
    } else {
      set_modal_data({ ...modal_data, channel_name: modal_data.channel_name });
    }
    set_search_val_modal("");
  };
  const handle_delete_channels = (clicked) => {
    const filter_channels = modal_data.channel_name.filter(
      (item) => item !== clicked,
    );
    set_modal_data({ ...modal_data, channel_name: filter_channels });
  };
  const channels_list_chips = (
    modal_data !== null && modal_data.channel_name
      ? modal_data.channel_name
      : []
  ).map((item, index) => (
    <Chip
      label={item}
      onDelete={() => handle_delete_channels(item)}
      color="success"
      key={index}
    />
  ));
  const handleChangeKeywordModal = (event) => {
    setKeyword_modal(event.target.value);
  };

  const handle = (props) => {
    const {
      // eslint-disable-next-line react/prop-types
      value,
      // eslint-disable-next-line react/prop-types
      dragging,
      // eslint-disable-next-line react/prop-types
      index,
      ...restProps
    } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} `}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };
  // Function for updating the tag alert filters from the modal
  const handle_change_exp_year = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_modal_data(
        modal_data.sub_value.exp_year.length === exp_year_options.length
          ? {
              ...modal_data,
              sub_value: { ...modal_data.sub_value, exp_year: [] },
            }
          : {
              ...modal_data,
              sub_value: {
                ...modal_data.sub_value,
                exp_year: exp_year_options,
              },
            },
      );
    } else {
      const num_value = Number(value);
      const list_exp_year = [...modal_data.sub_value.exp_year];
      const index_exp_year = list_exp_year.indexOf(num_value);
      index_exp_year === -1
        ? list_exp_year.push(num_value)
        : list_exp_year.splice(index_exp_year, 1);
      set_modal_data({
        ...modal_data,
        sub_value: { ...modal_data.sub_value, exp_year: list_exp_year },
      });
    }
  };
  const handle_change_exp_month = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_modal_data(
        modal_data.sub_value.exp_month.length === exp_month_options.length
          ? {
              ...modal_data,
              sub_value: { ...modal_data.sub_value, exp_month: [] },
            }
          : {
              ...modal_data,
              sub_value: {
                ...modal_data.sub_value,
                exp_month: exp_month_options,
              },
            },
      );
    } else {
      const num_value = Number(value);
      const list_exp_month = [...modal_data.sub_value.exp_month];
      const index_exp_month = list_exp_month.indexOf(num_value);
      index_exp_month === -1
        ? list_exp_month.push(num_value)
        : list_exp_month.splice(index_exp_month, 1);
      set_modal_data({
        ...modal_data,
        sub_value: { ...modal_data.sub_value, exp_month: list_exp_month },
      });
    }
  };
  const handle_change_card_type = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_modal_data(
        modal_data.sub_value.bank_card_type.length === card_type_options.length
          ? {
              ...modal_data,
              sub_value: { ...modal_data.sub_value, bank_card_type: [] },
            }
          : {
              ...modal_data,
              sub_value: {
                ...modal_data.sub_value,
                bank_card_type: card_type_options,
              },
            },
      );
    } else {
      const list_card_type = [...modal_data.sub_value.bank_card_type];
      const index_card_type = list_card_type.indexOf(value);
      index_card_type === -1
        ? list_card_type.push(value)
        : list_card_type.splice(index_card_type, 1);
      set_modal_data({
        ...modal_data,
        sub_value: { ...modal_data.sub_value, bank_card_type: list_card_type },
      });
    }
  };
  const handle_change_crypto_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_modal_data(
        modal_data.sub_value.crypto_type.length === crypto_options.length
          ? {
              ...modal_data,
              sub_value: { ...modal_data.sub_value, crypto_type: [] },
            }
          : {
              ...modal_data,
              sub_value: {
                ...modal_data.sub_value,
                crypto_type: crypto_options,
              },
            },
      );
    } else {
      const list_crypto_provider = [...modal_data.sub_value.crypto_type];
      const index_crypto_provider = list_crypto_provider.indexOf(value);
      index_crypto_provider === -1
        ? list_crypto_provider.push(value)
        : list_crypto_provider.splice(index_crypto_provider, 1);
      set_modal_data({
        ...modal_data,
        sub_value: {
          ...modal_data.sub_value,
          crypto_type: list_crypto_provider,
        },
      });
    }
  };
  const handle_change_email_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_modal_data(
        modal_data.sub_value.email.length === email_options.length
          ? { ...modal_data, sub_value: { ...modal_data.sub_value, email: [] } }
          : {
              ...modal_data,
              sub_value: { ...modal_data.sub_value, email: email_options },
            },
      );
    } else {
      const list_email_provider = [...modal_data.sub_value.email];
      const index_email_provider = list_email_provider.indexOf(value);
      index_email_provider === -1
        ? list_email_provider.push(value)
        : list_email_provider.splice(index_email_provider, 1);
      set_modal_data({
        ...modal_data,
        sub_value: { ...modal_data.sub_value, email: list_email_provider },
      });
    }
  };
  const val_change_modal = (e) => {
    set_search_val_modal(e.target.value);
    if (e.target.value.length > 2) {
      set_stat_loading(true);
      const data = { qtext: e.target.value, search_type: "alert" };
      axios
        .post("https://dev.recordedtelegram.com/search_fields", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const filter_data = res.data.filter((item) => item.link !== "None");
          set_search_data_modal(filter_data);
          set_stat_loading(false);
        })
        .catch((err) => {
          try {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            set_stat_loading(false);
            set_search_error(err.response.data.errormsg);
          } catch {
            //Do nothing
          }
        });
    } else {
      set_search_data_modal(null);
    }
  };

  const handle_onchange_update = (event) => {
    if (modal_data.notification_filter !== "nested") {
      set_modal_data({ ...modal_data, keyword_entered: event.target.value });
    } else {
      set_search_val_modal(event.target.value);
    }
    if (
      modal_data.notification_type === "channel" &&
      modal_data.notification_filter === "Default"
    ) {
      set_channel_name_clicked(event.target.value);
    }
    if (modal_data.notification_type === "channel") {
      val_change_modal(event);
    }
  };

  return (
    <div
      className="modal fade"
      id="updatefilters"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="updatefilters"
      aria-hidden="true"
      style={{ background: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog" role="document">
        {/* {users.modal_data.length !== 0 ? */}
        <div
          className="modal-content dk-mode scroll-custom "
          style={{ top: "20px", width: "580px", height: "90vh" }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="userModalLongTitle">
              Post Filters
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <CancelIcon
                sx={{ color: "#ff1f1f ", cursor: "pointer", m: 0.5 }}
              />
            </button>
          </div>
          <div className="modal-body">
            <div className="cardbox-item">
              <div className="row">
                <div className="col text-left">
                  <Formik
                    initialValues={{ qtext: "" }}
                    validate={(values) => {
                      const errors = {};
                      if (values.qtext.length === 0) {
                        errors.qtext = "";
                      }

                      return errors;
                    }}
                    onSubmit={() => {
                      setTimeout(() => {}, 400);
                    }}
                  >
                    {({
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      /* and other goodies */
                    }) =>
                      modal_data ? (
                        <form onSubmit={handleSubmit} autoComplete="off">
                          <FormLabel
                            id="demo-controlled-radio-buttons-group"
                            className="label__title"
                          >
                            {modal_data.notification_type === "channel"
                              ? "Channel/Group"
                              : "Search"}
                          </FormLabel>
                          <br />
                          {modal_data.notification_type === "stealer_logs" &&
                          modal_data.notification_sub_filter === "bulk" ? (
                            <textarea
                              type="text"
                              className="global_input_box w-75"
                              name="qtext"
                              onChange={(e) => {
                                handleChange(e);
                                handle_onchange_update(e);
                              }}
                              onBlur={handleBlur}
                              value={modal_data.keyword_entered}
                            />
                          ) : (
                            <input
                              type="text"
                              className="global_input_box w-75"
                              name="qtext"
                              onChange={(e) => {
                                handleChange(e);
                                handle_onchange_update(e);
                              }}
                              onBlur={handleBlur}
                              value={
                                modal_data.notification_type === "channel" &&
                                modal_data.notification_filter === "nested"
                                  ? search_val_modal
                                  : modal_data.notification_type ===
                                      "channel" &&
                                    modal_data.notification_filter === "Default"
                                  ? channel_name_clicked
                                  : modal_data.keyword_entered
                              }
                            />
                          )}
                          {modal_data.notification_type === "channel" &&
                          modal_data.notification_filter === "nested" ? (
                            <div style={{ marginTop: "5px" }}>
                              {channels_list_chips}
                            </div>
                          ) : null}

                          {/* list of channels */}
                          {search_val_modal.length > 2 &&
                          modal_data.notification_type === "channel" ? (
                            <div className="autocom-box w-80 mt-3">
                              {stat_loading ? (
                                <RollerAnimation />
                              ) : search_error !== null ? (
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontSize: "larger",
                                    color: "white",
                                    marginTop: "10%",
                                  }}
                                >
                                  {search_error}
                                </div>
                              ) : search_data_modal !== null &&
                                search_data_modal.length === 0 ? (
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontSize: "larger",
                                    color: "white",
                                    marginTop: "10%",
                                  }}
                                >
                                  No Data to display
                                </div>
                              ) : (
                                <>
                                  <h4
                                    style={{
                                      paddingLeft: "1rem",
                                      marginLeft: "15px",
                                      marginTop: "5px",
                                      fontSize: "18px",
                                      color: "whitesmoke",
                                    }}
                                  >
                                    List Of Channels
                                  </h4>
                                  <ul>
                                    {search_data_modal !== null &&
                                      search_data_modal.map((val, index) => (
                                        <li key={index}>
                                          <div className="row">
                                            <div
                                              className="col-md-8"
                                              style={{
                                                textAlign: "left",
                                              }}
                                            >
                                              {" "}
                                              <img
                                                style={{
                                                  width: "36px",
                                                  height: "36px",
                                                  padding: "2px",
                                                  border: "2px solid #f4f4f4",
                                                }}
                                                className="img-fluid rounded-circle"
                                                src={
                                                  val.channel_id !== "None"
                                                    ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                                }
                                                onError={(e) => {
                                                  e.target.onerror = null;
                                                  e.target.src =
                                                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                                                }}
                                                alt="User"
                                              />
                                              <span
                                                className="channels_list_map"
                                                style={{
                                                  marginLeft: "10px",
                                                  color: "#dcf3ff",
                                                }}
                                              >
                                                {val.channel_name}
                                              </span>
                                            </div>
                                            <div
                                              className="col-md-4"
                                              style={{
                                                textAlign: "right",
                                                marginTop: "5px",
                                              }}
                                            >
                                              {modal_data.notification_filter !==
                                              "nested" ? (
                                                <button
                                                  type="button"
                                                  style={{
                                                    borderRadius: "40px",
                                                    background: "#d03940",
                                                    color: "white",
                                                    lineHeight: "1",
                                                  }}
                                                  className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                                  onClick={() => {
                                                    set_modal_data({
                                                      ...modal_data,
                                                      keyword_entered:
                                                        val.channel_name !==
                                                        "None"
                                                          ? val.channel_name
                                                          : val.link !== "None"
                                                          ? val.link
                                                          : val.channel_id,
                                                    });
                                                    set_channel_name_clicked(
                                                      val.channel_name,
                                                    );
                                                  }}
                                                >
                                                  Add
                                                </button>
                                              ) : modal_data.channel_name &&
                                                modal_data.channel_name.includes(
                                                  val.channel_name,
                                                ) === false ? (
                                                <button
                                                  type="button"
                                                  style={{
                                                    borderRadius: "40px",
                                                    background: "#d03940",
                                                    color: "white",
                                                    lineHeight: "1",
                                                  }}
                                                  className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                                  onClick={() =>
                                                    add_channels_list(
                                                      val.channel_name,
                                                    )
                                                  }
                                                >
                                                  Add
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  disabled
                                                  style={{
                                                    borderRadius: "40px",
                                                    background: "#4cabd9",
                                                    color: "white",
                                                    lineHeight: "1",
                                                  }}
                                                  className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                                >
                                                  Added
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                </>
                              )}
                            </div>
                          ) : null}

                          {modal_data.notification_type === "channel" &&
                          modal_data.notification_filter === "nested" ? (
                            <div>
                              <FormLabel
                                id="demo-controlled-radio-buttons-group"
                                className="label__title"
                              >
                                Search
                              </FormLabel>
                              <br />
                              <input
                                type="text"
                                className="global_input_box w-75"
                                name="keyword"
                                onChange={(e) => {
                                  handleChange(e);
                                  set_modal_data({
                                    ...modal_data,
                                    keyword_entered: e.target.value,
                                  });
                                }}
                                onBlur={handleBlur}
                                value={modal_data.keyword_entered}
                              />
                            </div>
                          ) : null}
                          {/* list of previous tag name */}
                          {show_tag_list_div === true ? (
                            <div className="autocom-box w-80 mt-3">
                              {stat_loading ? (
                                <RollerAnimation />
                              ) : tag_name_list !== null &&
                                tag_name_list.length === 0 ? (
                                <div
                                  style={{
                                    textAlign: "center",
                                    fontSize: "larger",
                                    color: "white",
                                    marginTop: "10%",
                                  }}
                                >
                                  No Data to display
                                </div>
                              ) : (
                                <>
                                  <h4
                                    style={{
                                      paddingLeft: "1rem",
                                      marginLeft: "15px",
                                      marginTop: "5px",
                                      fontSize: "18px",
                                      color: "whitesmoke",
                                    }}
                                  >
                                    List Of Tag Names
                                  </h4>
                                  <ul>
                                    {tag_name_list !== null &&
                                      tag_name_list.map((val, id) => (
                                        <li key={id}>
                                          <div className="row">
                                            <div
                                              className="col-md-8"
                                              style={{ textAlign: "left" }}
                                            >
                                              {" "}
                                              <LabelIcon fontSize="large" />
                                              <span
                                                className="channels_list_map"
                                                style={{
                                                  marginLeft: "10px",
                                                  color: "#dcf3ff",
                                                }}
                                              >
                                                {val}
                                              </span>
                                            </div>
                                            <div
                                              className="col-md-4"
                                              style={{
                                                textAlign: "right",
                                                marginTop: "5px",
                                              }}
                                            >
                                              <button
                                                type="button"
                                                style={{
                                                  borderRadius: "40px",
                                                  background: "#d03940",
                                                  color: "white",
                                                  lineHeight: "1",
                                                }}
                                                className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                                onClick={() => {
                                                  set_modal_data({
                                                    ...modal_data,
                                                    tag_name: val,
                                                  });
                                                  set_show_tag_list_div(false);
                                                }}
                                              >
                                                Add
                                              </button>
                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                </>
                              )}
                            </div>
                          ) : null}

                          <FormControl sx={{ mt: 1 }}>
                            <FormLabel
                              id="demo-controlled-radio-buttons-group"
                              className="label__title"
                            >
                              Interval Type
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={interval_type}
                              onChange={(event) => {
                                set_interval_type(event.target.value);
                                set_interval_number(
                                  event.target.value === "minutes" ? 5 : 1,
                                );
                              }}
                            >
                              {modal_data &&
                                modal_data.notification_type !== "breach" && (
                                  <FormControlLabel
                                    value="day"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Day"
                                  />
                                )}
                              <FormControlLabel
                                value="week"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      color: "white",
                                      "&.Mui-checked": {
                                        color: "#28cab3",
                                      },
                                    }}
                                  />
                                }
                                label="Week"
                              />
                              {modal_data &&
                                modal_data.notification_type !== "breach" &&
                                modal_data.notification_type !==
                                  "brand_protection" && (
                                  <FormControlLabel
                                    value="minutes"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Minutes"
                                  />
                                )}
                            </RadioGroup>
                          </FormControl>

                          {modal_data.notification_type !== "breach" &&
                            modal_data.notification_type !== "channel" &&
                            modal_data.notification_type !== "tag" &&
                            modal_data.notification_type !== "stealer_logs" &&
                            modal_data.notification_type !==
                              "brand_protection" && (
                              <FormControl>
                                <FormLabel
                                  id="demo-controlled-radio-buttons-group"
                                  className="label__title"
                                >
                                  Filters
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  row
                                  name="controlled-radio-buttons-group"
                                  value={notification_filters_modal}
                                  onChange={(e) =>
                                    set_notificaton_filters_modal(
                                      e.target.value,
                                    )
                                  }
                                >
                                  <FormControlLabel
                                    value="Default"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Default"
                                  />
                                  <FormControlLabel
                                    value="regex"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Regex"
                                  />
                                  <FormControlLabel
                                    value="logical"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Logical"
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}
                          {modal_data.notification_type === "breach" && (
                            <FormControl>
                              <FormLabel
                                id="demo-controlled-radio-buttons-group"
                                className="label__title"
                              >
                                Filters
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                row
                                name="controlled-radio-buttons-group"
                                value={notification_filters_modal}
                                onChange={(e) =>
                                  set_notificaton_filters_modal(e.target.value)
                                }
                              >
                                <FormControlLabel
                                  value="domain"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="Domain"
                                />
                                <FormControlLabel
                                  value="phone"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="Phone"
                                />
                                <FormControlLabel
                                  value="name"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="Name"
                                />
                                <FormControlLabel
                                  value="email"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="Email"
                                />
                                <FormControlLabel
                                  value="username"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="Username"
                                />
                                <FormControlLabel
                                  value="ip"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="IP"
                                />
                                <FormControlLabel
                                  value="password"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="Password"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}

                          {modal_data.notification_type === "stealer_logs" && (
                            <FormControl>
                              <FormLabel
                                id="demo-controlled-radio-buttons-group"
                                className="label__title"
                              >
                                Filters
                              </FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                row
                                value={notification_filters_modal}
                                onChange={(e) =>
                                  set_notificaton_filters_modal(e.target.value)
                                }
                              >
                                <FormControlLabel
                                  value="email"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="Username/Email"
                                />
                                <FormControlLabel
                                  value="domain"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="Domain"
                                />
                                <FormControlLabel
                                  value="password"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="Password"
                                />
                                <FormControlLabel
                                  value="country_code"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="Country Code"
                                />
                                <FormControlLabel
                                  value="ip_address"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="IP"
                                />
                                <FormControlLabel
                                  value="zip"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="Zip"
                                />
                                <FormControlLabel
                                  value="location"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="Location"
                                />
                                <FormControlLabel
                                  value="device_id"
                                  control={
                                    <Radio
                                      size="small"
                                      sx={{
                                        color: "white",
                                        "&.Mui-checked": {
                                          color: "#28cab3",
                                        },
                                      }}
                                    />
                                  }
                                  label="MID/HWID/GUID"
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                          <p
                            className="card-text "
                            style={{
                              fontWeight: 900,
                              color: "var(--headingColor)",
                            }}
                          >
                            {" "}
                            Interval/Duration
                          </p>
                          <div className="row">
                            <div className="col text-left">
                              {modal_data !== null ? (
                                <div style={{ width: 250, marginLeft: "25px" }}>
                                  <Slider
                                    min={interval_type === "minutes" ? 5 : 1}
                                    max={
                                      interval_type === "minutes"
                                        ? 1439
                                        : interval_type === "week"
                                        ? 4
                                        : 7
                                    }
                                    value={interval_number}
                                    handle={handle}
                                    onChange={(e) => {
                                      set_interval_number(e);
                                    }}
                                  />
                                  <p
                                    className="txtslider"
                                    style={{
                                      color: "#fff",
                                      fontWeight: 500,
                                      marginLeft: "80px",
                                    }}
                                  >
                                    Interval:{" "}
                                    {interval_type === "minutes"
                                      ? interval_number >= 60
                                        ? `${Math.floor(
                                            Number(interval_number) / 60,
                                          )} hr ${
                                            Number(interval_number) % 60
                                          } mins`
                                        : `${interval_number} mins`
                                      : interval_number}
                                  </p>
                                </div>
                              ) : null}
                            </div>
                            {modal_data.notification_type ===
                              "stealer_logs" && (
                              <FormControl>
                                <FormLabel
                                  id="demo-controlled-radio-buttons-group"
                                  className="label__title"
                                >
                                  Category
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  row
                                  name="controlled-radio-buttons-group"
                                  value={modal_data.stealer_category}
                                  onChange={(e) =>
                                    set_modal_data((prev) => ({
                                      ...prev,
                                      stealer_category: e.target.value,
                                    }))
                                  }
                                  sx={{ columnGap: 2 }}
                                >
                                  <FormControlLabel
                                    value={"hacking"}
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Hacking"
                                  />
                                  <FormControlLabel
                                    value={"pedophiles"}
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Pedophiles"
                                  />
                                  <FormControlLabel
                                    value={"russian defense"}
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Russian Defense"
                                  />
                                  <FormControlLabel
                                    value={"terrorism"}
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Terrorism"
                                  />
                                  <FormControlLabel
                                    value={"off"}
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="OFF"
                                  />
                                </RadioGroup>
                              </FormControl>
                            )}
                            {modal_data &&
                            modal_data.notification_type !== "channel" &&
                            modal_data.notification_filter === "Default" &&
                            modal_data.notification_type !== "breach" &&
                            modal_data.notification_type !==
                              "brand_protection" ? (
                              <FormControl>
                                <FormLabel
                                  id="demo-controlled-radio-buttons-group"
                                  className="label__title"
                                >
                                  Keyword
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="controlled-radio-buttons-group"
                                  value={keyword_modal}
                                  onChange={handleChangeKeywordModal}
                                >
                                  <FormControlLabel
                                    value="contains"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Contains"
                                  />
                                  <FormControlLabel
                                    value="exact"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Exact"
                                  />
                                </RadioGroup>
                              </FormControl>
                            ) : null}

                            {modal_data &&
                            modal_data.notification_type === "stealer_logs" ? (
                              <FormControl>
                                <FormLabel
                                  id="demo-controlled-radio-buttons-group"
                                  className="label__title"
                                >
                                  Keyword
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="controlled-radio-buttons-group"
                                  value={keyword_modal}
                                  onChange={handleChangeKeywordModal}
                                >
                                  <FormControlLabel
                                    value="contains"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Contains"
                                  />
                                  <FormControlLabel
                                    value="exact"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Exact"
                                  />
                                  <FormControlLabel
                                    value="regex"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Regex"
                                  />
                                </RadioGroup>
                              </FormControl>
                            ) : null}

                            {handle_session_get("Acc_typ") ===
                              "PAID_CUSTOMER" ||
                            handle_session_get("User") === "administrator" ? (
                              <FormControl>
                                <FormLabel
                                  id="demo-controlled-radio-buttons-group"
                                  className="label__title"
                                >
                                  Alert Type
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  name="controlled-radio-buttons-group"
                                  value={alert_type_modal}
                                  onChange={(e) => {
                                    set_alert_type_modal(e.target.value);
                                    if (e.target.value === "custom_endpoint") {
                                      set_modal_data({
                                        ...modal_data,
                                        alert_file: "csv",
                                      });
                                    }
                                    if (e.target.value === "multiple_email") {
                                      if (!modal_data.email_type) {
                                        set_modal_data((prev) => ({
                                          ...prev,
                                          email_type: "cc",
                                        }));
                                      }
                                    }
                                  }}
                                >
                                  <FormControlLabel
                                    value="email"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="email"
                                  />
                                  <FormControlLabel
                                    value="custom_endpoint"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Endpoint"
                                  />
                                  <FormControlLabel
                                    value="multiple_email"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="Multiple Emails"
                                  />
                                </RadioGroup>
                              </FormControl>
                            ) : null}

                            {alert_type_modal === "multiple_email" ? (
                              <FormControl>
                                <FormLabel
                                  id="demo-controlled-radio-buttons-group"
                                  className="label__title"
                                >
                                  Emails
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  row
                                  name="controlled-radio-buttons-group"
                                  value={modal_data.email_type}
                                  onChange={(e) => {
                                    set_modal_data((prev) => ({
                                      ...prev,
                                      email_type: e.target.value,
                                    }));
                                  }}
                                >
                                  <FormControlLabel
                                    value="cc"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="CC"
                                  />
                                  <FormControlLabel
                                    value="bcc"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="BCC"
                                  />
                                </RadioGroup>
                              </FormControl>
                            ) : null}

                            {/* Alert file type for Update */}
                            {modal_data.notification_type !== "breach" &&
                            modal_data.notification_type !== "tag" &&
                            modal_data.notification_type !== "stealer_logs" &&
                            (alert_type_modal === "email" ||
                              alert_type_modal === "multiple_email") ? (
                              <FormControl>
                                <FormLabel
                                  id="demo-controlled-radio-buttons-group"
                                  className="label__title"
                                >
                                  File Type
                                </FormLabel>
                                <RadioGroup
                                  aria-labelledby="demo-controlled-radio-buttons-group"
                                  row
                                  name="controlled-radio-buttons-group"
                                  value={
                                    modal_data.alert_file
                                      ? modal_data.alert_file
                                      : "csv"
                                  }
                                  onChange={(e) => {
                                    set_modal_data({
                                      ...modal_data,
                                      alert_file: e.target.value,
                                    });
                                  }}
                                >
                                  <FormControlLabel
                                    value="csv"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="CSV"
                                  />
                                  <FormControlLabel
                                    value="html"
                                    control={
                                      <Radio
                                        size="small"
                                        sx={{
                                          color: "white",
                                          "&.Mui-checked": {
                                            color: "#28cab3",
                                          },
                                        }}
                                      />
                                    }
                                    label="HTML"
                                  />
                                </RadioGroup>
                              </FormControl>
                            ) : null}

                            {/* Alert Post no for update */}
                            {modal_data.notification_type !== "breach" &&
                            modal_data.notification_type !== "tag" &&
                            modal_data.notification_type !==
                              "brand_protection" &&
                            (alert_type_modal === "email" ||
                              alert_type_modal === "multiple_email") ? (
                              modal_data.alert_file &&
                              modal_data.alert_file === "html" ? (
                                <FormControl>
                                  <FormLabel
                                    id="demo-controlled-radio-buttons-group"
                                    className="label__title"
                                  >
                                    Post
                                  </FormLabel>
                                  <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    row
                                    name="controlled-radio-buttons-group"
                                    value={
                                      modal_data.no_of_posts === 0
                                        ? modal_data.no_of_posts
                                        : modal_data.no_of_posts
                                        ? modal_data.no_of_posts
                                        : modal_data.notification_type ===
                                            "keyword" ||
                                          modal_data.notification_type ===
                                            "file" ||
                                          modal_data.notification_type ===
                                            "file_extension"
                                        ? 3
                                        : 5
                                    }
                                    onChange={(e) => {
                                      set_modal_data({
                                        ...modal_data,
                                        no_of_posts: e.target.value,
                                      });
                                    }}
                                  >
                                    <FormControlLabel
                                      value={0}
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label={0}
                                    />
                                    <FormControlLabel
                                      value={1}
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label={1}
                                    />
                                    <FormControlLabel
                                      value={
                                        modal_data.notification_type ===
                                          "keyword" ||
                                        modal_data.notification_type ===
                                          "file" ||
                                        modal_data.notification_type ===
                                          "file_extension"
                                          ? 2
                                          : 5
                                      }
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label={
                                        modal_data.notification_type ===
                                          "keyword" ||
                                        modal_data.notification_type ===
                                          "file" ||
                                        modal_data.notification_type ===
                                          "file_extension"
                                          ? 2
                                          : 5
                                      }
                                    />
                                    <FormControlLabel
                                      value={
                                        modal_data.notification_type ===
                                          "keyword" ||
                                        modal_data.notification_type ===
                                          "file" ||
                                        modal_data.notification_type ===
                                          "file_extension"
                                          ? 3
                                          : 10
                                      }
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label={
                                        modal_data.notification_type ===
                                          "keyword" ||
                                        modal_data.notification_type ===
                                          "file" ||
                                        modal_data.notification_type ===
                                          "file_extension"
                                          ? 3
                                          : 10
                                      }
                                    />
                                    <FormControlLabel
                                      value={
                                        modal_data.notification_type ===
                                          "keyword" ||
                                        modal_data.notification_type ===
                                          "file" ||
                                        modal_data.notification_type ===
                                          "file_extension"
                                          ? 4
                                          : 15
                                      }
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label={
                                        modal_data.notification_type ===
                                          "keyword" ||
                                        modal_data.notification_type ===
                                          "file" ||
                                        modal_data.notification_type ===
                                          "file_extension"
                                          ? 4
                                          : 15
                                      }
                                    />
                                    <FormControlLabel
                                      value={
                                        modal_data.notification_type ===
                                          "keyword" ||
                                        modal_data.notification_type ===
                                          "file" ||
                                        modal_data.notification_type ===
                                          "file_extension"
                                          ? 5
                                          : 20
                                      }
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                              color: "#28cab3",
                                            },
                                          }}
                                        />
                                      }
                                      label={
                                        modal_data.notification_type ===
                                          "keyword" ||
                                        modal_data.notification_type ===
                                          "file" ||
                                        modal_data.notification_type ===
                                          "file_extension"
                                          ? 5
                                          : 20
                                      }
                                    />
                                  </RadioGroup>
                                </FormControl>
                              ) : null
                            ) : null}

                            {/* Tag alert update filters */}
                            {modal_data &&
                            modal_data.notification_type === "tag"
                              ? (modal_data.notification_filter ===
                                  "bank_card" ||
                                  modal_data.notification_filter === "bin") && (
                                  <FormControl>
                                    <FormLabel
                                      id="demo-controlled-radio-buttons-group"
                                      className="label__title"
                                    >
                                      Exp Year
                                    </FormLabel>
                                    <Box
                                      className="filter__category_modal "
                                      id="scroll_filter"
                                    >
                                      {exp_year_options.map((item, id) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              value={item}
                                              onChange={handle_change_exp_year}
                                              checked={modal_data.sub_value.exp_year.includes(
                                                item,
                                              )}
                                              size="small"
                                              sx={{
                                                color: "white",
                                                "&.Mui-checked": {
                                                  color: "#28cab3",
                                                },
                                              }}
                                            />
                                          }
                                          label={item}
                                          key={id}
                                        />
                                      ))}

                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value="all"
                                            onChange={handle_change_exp_year}
                                            checked={
                                              modal_data.sub_value.exp_year
                                                .length ===
                                              exp_year_options.length
                                            }
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="All"
                                      />
                                    </Box>
                                  </FormControl>
                                )
                              : null}

                            {modal_data &&
                            modal_data.notification_type === "tag"
                              ? (modal_data.notification_filter ===
                                  "bank_card" ||
                                  modal_data.notification_filter === "bin") && (
                                  <FormControl>
                                    <FormLabel
                                      id="demo-controlled-radio-buttons-group"
                                      className="label__title"
                                    >
                                      Exp Month
                                    </FormLabel>
                                    <Box
                                      className="filter__category_modal "
                                      id="scroll_filter"
                                    >
                                      {exp_month_options.map((item, id) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              value={item}
                                              onChange={handle_change_exp_month}
                                              checked={modal_data.sub_value.exp_month.includes(
                                                item,
                                              )}
                                              size="small"
                                              sx={{
                                                color: "white",
                                                "&.Mui-checked": {
                                                  color: "#28cab3",
                                                },
                                              }}
                                            />
                                          }
                                          label={item}
                                          key={id}
                                        />
                                      ))}

                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value="all"
                                            onChange={handle_change_exp_month}
                                            checked={
                                              modal_data.sub_value.exp_month
                                                .length ===
                                              exp_month_options.length
                                            }
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="All"
                                      />
                                    </Box>
                                  </FormControl>
                                )
                              : null}

                            {modal_data &&
                            modal_data.notification_type === "tag"
                              ? (modal_data.notification_filter ===
                                  "bank_card" ||
                                  modal_data.notification_filter === "bin") && (
                                  <FormControl>
                                    <FormLabel
                                      id="demo-controlled-radio-buttons-group"
                                      className="label__title"
                                    >
                                      Card Type
                                    </FormLabel>
                                    <Box
                                      className="filter__category_modal "
                                      id="scroll_filter"
                                    >
                                      {card_type_options.map((item, id) => {
                                        let split_card_name = "";
                                        if (
                                          item ===
                                          "universal_air_travel_plan_card"
                                        ) {
                                          split_card_name = "UATP";
                                        } else if (item === "jcb_card") {
                                          split_card_name = "JCB";
                                        } else if (
                                          [
                                            "amex_card",
                                            "mastercard",
                                            "visa_card",
                                          ].includes(item) === false
                                        ) {
                                          split_card_name = item.replaceAll(
                                            "card",
                                            "",
                                          );
                                        } else {
                                          split_card_name = item;
                                        }
                                        split_card_name =
                                          split_card_name.replaceAll("_", " ");
                                        return (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                value={item}
                                                onChange={
                                                  handle_change_card_type
                                                }
                                                checked={modal_data.sub_value.bank_card_type.includes(
                                                  item,
                                                )}
                                                size="small"
                                                sx={{
                                                  color: "white",
                                                  "&.Mui-checked": {
                                                    color: "#28cab3",
                                                  },
                                                }}
                                              />
                                            }
                                            label={
                                              <span className="card_name">
                                                {split_card_name}
                                              </span>
                                            }
                                            key={id}
                                          />
                                        );
                                      })}

                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value="all"
                                            onChange={handle_change_card_type}
                                            checked={
                                              modal_data.sub_value
                                                .bank_card_type.length ===
                                              card_type_options.length
                                            }
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="All"
                                      />
                                    </Box>
                                  </FormControl>
                                )
                              : null}

                            {modal_data &&
                            modal_data.notification_type === "tag"
                              ? modal_data.notification_filter === "email" && (
                                  <FormControl>
                                    <FormLabel
                                      id="demo-controlled-radio-buttons-group"
                                      className="label__title"
                                    >
                                      Email Provider
                                    </FormLabel>
                                    <Box
                                      className="filter__category_modal "
                                      id="scroll_filter"
                                    >
                                      {email_options.map((item, id) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              value={item}
                                              onChange={
                                                handle_change_email_provider
                                              }
                                              checked={modal_data.sub_value.email.includes(
                                                item,
                                              )}
                                              size="small"
                                              sx={{
                                                color: "white",
                                                "&.Mui-checked": {
                                                  color: "#28cab3",
                                                },
                                              }}
                                            />
                                          }
                                          label={
                                            <span className="card_name">
                                              {item}
                                            </span>
                                          }
                                          key={id}
                                        />
                                      ))}

                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value="all"
                                            onChange={
                                              handle_change_email_provider
                                            }
                                            checked={
                                              modal_data.sub_value.email
                                                .length === email_options.length
                                            }
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="All"
                                      />
                                    </Box>
                                  </FormControl>
                                )
                              : null}

                            {modal_data &&
                            modal_data.notification_type === "tag"
                              ? modal_data.notification_filter === "crypto" && (
                                  <FormControl>
                                    <FormLabel
                                      id="demo-controlled-radio-buttons-group"
                                      className="label__title"
                                    >
                                      Crypto Provider
                                    </FormLabel>
                                    <Box
                                      className="filter__category_modal "
                                      id="scroll_filter"
                                    >
                                      {crypto_options.map((item, id) => (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              value={item}
                                              onChange={
                                                handle_change_crypto_provider
                                              }
                                              checked={modal_data.sub_value.crypto_type.includes(
                                                item,
                                              )}
                                              size="small"
                                              sx={{
                                                color: "white",
                                                "&.Mui-checked": {
                                                  color: "#28cab3",
                                                },
                                              }}
                                            />
                                          }
                                          label={
                                            <span className="card_name">
                                              {item}
                                            </span>
                                          }
                                          key={id}
                                        />
                                      ))}

                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value="all"
                                            onChange={
                                              handle_change_crypto_provider
                                            }
                                            checked={
                                              modal_data.sub_value.crypto_type
                                                .length ===
                                              crypto_options.length
                                            }
                                            size="small"
                                            sx={{
                                              color: "white",
                                              "&.Mui-checked": {
                                                color: "#28cab3",
                                              },
                                            }}
                                          />
                                        }
                                        label="All"
                                      />
                                    </Box>
                                  </FormControl>
                                )
                              : null}

                            {/* Category in Modal for updating */}
                            {modal_data &&
                            modal_data.notification_type !== "channel" &&
                            modal_data.notification_type !== "breach" &&
                            modal_data.notification_type !==
                              "brand_protection" &&
                            modal_data.notification_type !== "stealer_logs" ? (
                              <Categories
                                postCategory={postCategory_modal}
                                setPostCategory={setPostCategory_modal}
                                container_type={"row"}
                              />
                            ) : null}

                            {modal_data.notification_type === "keyword" &&
                            modal_data.lang_obj &&
                            modal_data.lang_obj !== "None" &&
                            modal_data.lang_obj.length > 0 ? (
                              <Stack>
                                <span
                                  className="card-text "
                                  style={{ fontWeight: 900, color: "#fff" }}
                                >
                                  {" "}
                                  Select Languages
                                </span>
                                <button
                                  className="load_more_btn"
                                  style={{ width: "25%", margin: 0 }}
                                  onClick={() => set_open_translate_modal(true)}
                                >
                                  Languages
                                </button>
                              </Stack>
                            ) : null}
                          </div>
                          <Stack direction="row" justifyContent="center">
                            <button
                              type="submit"
                              style={{
                                marginTop: "20px",
                              }}
                              data-dismiss="modal"
                              className="button_global_style"
                              onClick={(e) => {
                                e.preventDefault();
                                post_data_modal(
                                  modal_data.keyword_entered,
                                  modal_data.notification_type,
                                  modal_data.regex_status,
                                  modal_data.id,
                                  modal_data.channel_name,
                                  "disable",
                                );
                              }}
                            >
                              Update
                            </button>
                          </Stack>
                        </form>
                      ) : null
                    }
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
UpdateAdminAlertFilters.propTypes = {
  modal_data: PropTypes.object,
  set_modal_data: PropTypes.func,
  keyword_modal: PropTypes.any,
  setKeyword_modal: PropTypes.func,
  postCategory_modal: PropTypes.array,
  setPostCategory_modal: PropTypes.func,
  interval_type: PropTypes.string,
  set_interval_type: PropTypes.func,
  interval_number: PropTypes.number,
  set_interval_number: PropTypes.func,
  notification_filters_modal: PropTypes.string,
  set_notificaton_filters_modal: PropTypes.func,
  alert_type_modal: PropTypes.string,
  set_alert_type_modal: PropTypes.func,
  post_data_modal: PropTypes.func,
  set_open_translate_modal: PropTypes.func,
};

export default UpdateAdminAlertFilters;
