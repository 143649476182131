import React, { memo } from "react";
import PropTypes from "prop-types";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { handle_warning_message } from "../../../utils/ResponseMessage/response_message";
import { Formik } from "formik";
import axios from "axios";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import { Stack } from "@mui/material";
import Categories from "../sections/Checkbox/Categories";
import {
  card_type_options,
  crypto_options,
  email_options,
  exp_year_options,
  exp_month_options,
} from "../../../utils/Globals/global_variables";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_check_category_length } from "../../../Assets/globalfunctions";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_determine_graph_or_table_data } from "../company_dashboard/companyglobalfunc";

function ChartFiltersModal({
  set_open_modal,
  chart_stats,
  set_chart_stats,
  stats_name,
  alert_type,
  alert_id,
  graph_type,
  postCategory,
  setPostCategory,
  stealer_multiple_obj,
  set_stealer_multiple_obj,
  category_stealer,
  set_category_stealer,
  keyword,
  setKeyword,
  post_search_sub,
  set_search_sub,
  card_type,
  set_card_type,
  crypto_provider,
  set_crypto_provider,
  email_provider,
  set_email_provider,
  exp_year,
  set_exp_year,
  exp_month,
  set_exp_month,
}) {
  const token = handle_session_get("Token");
  const handle_change_stealer_input = (event) => {
    const { id } = event.target;
    const { value } = event.target;
    set_stealer_multiple_obj((prev) => ({ ...prev, [id]: value }));
  };
  const handle_check_options_length = (options, selected) => {
    try {
      if (options.length === selected.length) return true;
      return false;
    } catch {
      return false;
    }
  };
  const handle_filters_alert_chart = (alert_id) => {
    let isSubmitBlocked = false;
    set_chart_stats((prev) => ({
      ...prev,
      [alert_type]: {
        ...prev[alert_type],
        [stats_name]: {
          ...prev[alert_type][stats_name],
          loading_chart: true,
          error: "",
        },
      },
    }));
    const requested_display = chart_stats[stats_name]["display_mode"];
    let query_param = "";
    for (let key in stealer_multiple_obj) {
      if (stealer_multiple_obj[key]) {
        query_param += `${key}=${stealer_multiple_obj[key]}&`;
      }
    }

    let route = "";
    if (alert_type === "stealer_logs") {
      route = `https://dev.recordedtelegram.com/alert_stats/?${query_param}alert_id=${alert_id}&stealer_category=${category_stealer}`;
    } else if (alert_type === "file" || alert_type === "keyword") {
      if (graph_type === "Default") {
        route = `https://dev.recordedtelegram.com/alert_stats/?dashboard_search_type=${keyword}&channel_type=${post_search_sub}&select_category=${
          handle_check_category_length(postCategory) === true
            ? "all"
            : postCategory.toString()
        }&alert_id=${alert_id}&dashboard_type=${requested_display}`;
      } else {
        route = `https://dev.recordedtelegram.com/alert_stats/?&channel_type=${post_search_sub}&select_category=${
          handle_check_category_length(postCategory) === true
            ? "all"
            : postCategory.toString()
        }&alert_id=${alert_id}&dashboard_type=${requested_display}`;
      }
    } else if (alert_type === "tag") {
      if (graph_type === "bank_card" || graph_type === "bin") {
        route = `https://dev.recordedtelegram.com/alert_stats/?bank_card_type=${
          handle_check_options_length(card_type_options, card_type) === true
            ? "all"
            : card_type.toString()
        }&expiry_year=${
          handle_check_options_length(exp_year_options, exp_year) === true
            ? "all"
            : exp_year.toString()
        }&expiry_month=${
          handle_check_options_length(exp_month_options, exp_month) === true
            ? "all"
            : exp_month.toString()
        }&select_category=${
          handle_check_category_length(postCategory) === true
            ? "all"
            : postCategory.toString()
        }&alert_id=${alert_id}&dashboard_type=${requested_display}`;
      } else if (graph_type === "email") {
        route = `https://dev.recordedtelegram.com/alert_stats/?email_provider=${
          handle_check_options_length(email_options, email_provider) === true
            ? "all"
            : email_provider.toString()
        }&select_category=${
          handle_check_category_length(postCategory) === true
            ? "all"
            : postCategory.toString()
        }&alert_id=${alert_id}&dashboard_type=${requested_display}`;
      } else if (graph_type === "crypto") {
        route = `https://dev.recordedtelegram.com/alert_stats/?crypto_type=${
          handle_check_options_length(crypto_options, crypto_provider) === true
            ? "all"
            : crypto_provider.toString()
        }&select_category=${
          handle_check_category_length(postCategory) === true
            ? "all"
            : postCategory.toString()
        }&alert_id=${alert_id}&dashboard_type=${requested_display}`;
      } else {
        route = `https://dev.recordedtelegram.com/alert_stats/?select_category=${
          handle_check_category_length(postCategory) === true
            ? "all"
            : postCategory.toString()
        }&alert_id=${alert_id}&dashboard_type=${requested_display}`;
      }
    } else {
      route = `https://dev.recordedtelegram.com/alert_stats/?alert_id=${alert_id}`;
    }
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .get(route, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const all_product_res = res.data.statistics[stats_name];
          const categories_obj = all_product_res["data"];
          if (alert_type === "keyword") {
            const restructuredData = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "keyword",
            )["sanitize_data"];
            const categories_channel_obj = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "keyword",
            )["sanitize_channel"];
            set_chart_stats((prev) => ({
              ...prev,
              [alert_type]: {
                ...prev[alert_type],
                [stats_name]: {
                  ...prev[alert_type][stats_name],
                  loading_chart: false,
                  error: "",
                  data: restructuredData,
                  all_response_data: categories_obj,
                  display_mode: all_product_res.display_module,
                  total_data: all_product_res.total_data,
                  categories_channel: categories_channel_obj,
                  alert_id: all_product_res.alert_id,
                },
              },
            }));
          } else if (alert_type === "tag") {
            const restructuredData = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "tag",
            )["sanitize_data"];
            const categories_channel_arr = handle_determine_graph_or_table_data(
              categories_obj,
              all_product_res["display_module"],
              "tag",
            )["sanitize_channel"];
            set_chart_stats((prev) => ({
              ...prev,
              [alert_type]: {
                ...prev[alert_type],
                [stats_name]: {
                  ...prev[alert_type][stats_name],
                  loading_chart: false,
                  error: "",
                  data: restructuredData,
                  all_response_data: categories_obj,
                  display_mode: all_product_res.display_module,
                  total_data: all_product_res.total_data,
                  categories_channel: categories_channel_arr,
                  alert_id: all_product_res.alert_id,
                },
              },
            }));
          } else {
            set_chart_stats((prev) => ({
              ...prev,
              [alert_type]: {
                ...prev[alert_type],
                [stats_name]: {
                  ...prev[alert_type][stats_name],
                  loading_chart: false,
                  error: "",
                  data: [...all_product_res.data],
                  alert_id: all_product_res.alert_id,
                  total_data: all_product_res.total_data,
                },
              },
            }));
          }
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_chart_stats((prev) => ({
            ...prev,
            [alert_type]: {
              ...prev[alert_type],
              [stats_name]: {
                ...prev[alert_type][stats_name],
                loading_chart: false,
                error: err.response.data.errormsg,
              },
            },
          }));
          isSubmitBlocked = false;
        });
    }
  };

  const isAllselectedExpYear =
    exp_year_options.length > 0 && exp_year.length === exp_year_options.length;

  const isAllselectedExpMonth =
    exp_month_options.length > 0 &&
    exp_month.length === exp_month_options.length;
  const isAllselectedCardType =
    card_type_options.length > 0 &&
    card_type.length === card_type_options.length;
  const isAllselectedCryptoProvider =
    crypto_options.length > 0 &&
    crypto_provider.length === crypto_options.length;
  const isAllselectedEmailProvider =
    email_options.length > 0 && email_provider.length === email_options.length;
  const handle_change_card_type = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_card_type(
        card_type.length === card_type_options.length ? [] : card_type_options,
      );
    } else {
      const list_card_type = [...card_type];
      const index_card_type = list_card_type.indexOf(value);
      index_card_type === -1
        ? list_card_type.push(value)
        : list_card_type.splice(index_card_type, 1);
      set_card_type(list_card_type);
    }
  };
  const handle_change_crypto_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_crypto_provider(
        crypto_provider.length === crypto_options.length ? [] : crypto_options,
      );
    } else {
      const list_crypto_provider = [...crypto_provider];
      const index_crypto_provider = list_crypto_provider.indexOf(value);
      index_crypto_provider === -1
        ? list_crypto_provider.push(value)
        : list_crypto_provider.splice(index_crypto_provider, 1);
      set_crypto_provider(list_crypto_provider);
    }
  };
  const handle_change_email_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_email_provider(
        email_provider.length === email_options.length ? [] : email_options,
      );
    } else {
      const list_email_provider = [...email_provider];
      const index_email_provider = list_email_provider.indexOf(value);
      index_email_provider === -1
        ? list_email_provider.push(value)
        : list_email_provider.splice(index_email_provider, 1);
      set_email_provider(list_email_provider);
    }
  };
  const handle_change_exp_year = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_exp_year(
        exp_year.length === exp_year_options.length ? [] : exp_year_options,
      );
    } else {
      const num_value = Number(value);
      const list_exp_year = [...exp_year];
      const index_exp_year = list_exp_year.indexOf(num_value);
      index_exp_year === -1
        ? list_exp_year.push(num_value)
        : list_exp_year.splice(index_exp_year, 1);
      set_exp_year(list_exp_year);
    }
  };
  const handle_change_exp_month = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_exp_month(
        exp_month.length === exp_month_options.length ? [] : exp_month_options,
      );
    } else {
      const num_value = Number(value);
      const list_exp_month = [...exp_month];
      const index_exp_month = list_exp_month.indexOf(num_value);
      index_exp_month === -1
        ? list_exp_month.push(num_value)
        : list_exp_month.splice(index_exp_month, 1);
      set_exp_month(list_exp_month);
    }
  };
  return (
    <ClickAwayListener onClickAway={() => set_open_modal(false)}>
      <div className="dashboard_chart_filters_modal scroll-custom">
        <Formik
          initialValues={{
            email: "",
            domain: "",
            password: "",
            country_code: "",
            ip_address: "",
            zip: "",
            location: "",
          }}
          validate={() => {
            const errors = {};
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (alert_type === "stealer_logs") {
              if (Object.keys(stealer_multiple_obj).length > 0) {
                if (Object.values(stealer_multiple_obj).length > 0) {
                  const filter_array = Object.values(
                    stealer_multiple_obj,
                  ).filter((item) => item !== "");
                  if (filter_array.length === 0) {
                    handle_warning_message(
                      "Please enter atleast one input field",
                    );
                    return;
                  }
                }
              } else if (Object.keys(stealer_multiple_obj).length === 0) {
                handle_warning_message("Please enter atleast one input field");
                return;
              }
            }
            setTimeout(() => {
              set_open_modal(false);
              setSubmitting(false);
              handle_filters_alert_chart(alert_id);
            });
          }}
        >
          {({ handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <h5 className="dashbord_chart_filter_title">Filter your data</h5>
              {alert_type === "stealer_logs" && (
                <div className="dashboard_chart_filters_modal_stealerslogs">
                  <div className="modal_stealer_logs_multiple_search_input_container">
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="email">
                        Email / Username
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.email}
                        placeholder="enter email/username"
                      />
                    </div>
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="domain">
                        Domain
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="domain"
                        name="domain"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.domain}
                        placeholder="enter domain"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="password"
                        name="password"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.password}
                        placeholder="enter password"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label
                        className="global_input_label"
                        htmlFor="country_code"
                      >
                        Country Code
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="country_code"
                        name="country_code"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.country_code}
                        placeholder="enter country code"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label
                        className="global_input_label"
                        htmlFor="ip_address"
                      >
                        IP Address
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="ip_address"
                        name="ip_address"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.ip_address}
                        placeholder="ente ip address"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="zip">
                        Zip
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="zip"
                        name="zip"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.zip}
                        placeholder="enter zip"
                      />
                    </div>

                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="location">
                        Location
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="location"
                        name="location"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.location}
                        placeholder="enter location"
                      />
                    </div>
                    <div className="modal_stealer_logs_multiple_search_input_label">
                      <label className="global_input_label" htmlFor="device_id">
                        MID/HWID/GUID
                      </label>
                      <input
                        type="text"
                        onChange={(event) => {
                          event.preventDefault();
                          handleChange(event);
                          handle_change_stealer_input(event);
                        }}
                        onBlur={handleBlur}
                        id="device_id"
                        name="device_id"
                        className="global_input_box w-100"
                        value={stealer_multiple_obj.device_id}
                        placeholder="enter MID/HWID/GUID"
                      />
                    </div>
                  </div>
                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                      sx={{ mt: 1 }}
                    >
                      Category
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={category_stealer}
                      onChange={(e) => set_category_stealer(e.target.value)}
                      sx={{ columnGap: 2 }}
                    >
                      <FormControlLabel
                        value={"hacking"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Hacking"
                      />
                      <FormControlLabel
                        value={"pedophiles"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Pedophiles"
                      />
                      <FormControlLabel
                        value={"russian defense"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Russian Defense"
                      />
                      <FormControlLabel
                        value={"terrorism"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Terrorism"
                      />
                      <FormControlLabel
                        value={"off"}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="OFF"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
              {(alert_type === "file" || alert_type === "keyword") && (
                <div className="dashboard_chart_filters_modal_keyword">
                  {/* {alert_type === "keyword" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Filters
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={telegramOption_post}
                        onChange={(e) =>
                          set_telegramOption_post(e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="None"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="All"
                        />

                        <FormControlLabel
                          value="conv_name"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Title"
                        />
                        <FormControlLabel
                          value="message"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Post"
                        />
                      </RadioGroup>
                    </FormControl>
                  )} */}
                  {graph_type === "Default" && (
                    <FormControl className="dashboard_chart_radio_options">
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Keyword
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                      >
                        <FormControlLabel
                          value="contains"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Contains"
                        />
                        <FormControlLabel
                          value="exact"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Exact"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  <FormControl className="dashboard_chart_radio_options">
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Type
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={post_search_sub}
                      onChange={(e) => set_search_sub(e.target.value)}
                    >
                      <FormControlLabel
                        value="channel"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Channel"
                      />
                      <FormControlLabel
                        value="group"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Group"
                      />
                      <FormControlLabel
                        value="Channel/Group"
                        control={
                          <Radio
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="Both"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Categories
                    postCategory={postCategory}
                    setPostCategory={setPostCategory}
                    container_type={"row"}
                  />
                </div>
              )}
              {alert_type === "tag" && (
                <div className="dashboard_chart_filters_modal_tag">
                  {(graph_type === "bank_card" || graph_type === "bin") && (
                    <div>
                      <FormControl>
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Card Type
                        </FormLabel>
                        <Stack direction="row" flexWrap="wrap">
                          {card_type_options.map((item, id) => {
                            let split_card_name = "";
                            if (item === "universal_air_travel_plan_card") {
                              split_card_name = "UATP";
                            } else if (item === "jcb_card") {
                              split_card_name = "JCB";
                            } else if (
                              ["amex_card", "mastercard", "visa_card"].includes(
                                item,
                              ) === false
                            ) {
                              split_card_name = item.replaceAll("card", "");
                            } else {
                              split_card_name = item;
                            }
                            split_card_name = split_card_name.replaceAll(
                              "_",
                              " ",
                            );
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={item}
                                    onChange={handle_change_card_type}
                                    checked={card_type.includes(item)}
                                    size="small"
                                    sx={{
                                      color: "white",
                                      "&.Mui-checked": {
                                        color: "#28cab3",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <span className="card_name">
                                    {split_card_name}
                                  </span>
                                }
                                key={id}
                              />
                            );
                          })}

                          <FormControlLabel
                            control={
                              <Checkbox
                                value="all"
                                onChange={handle_change_card_type}
                                checked={isAllselectedCardType}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="All"
                          />
                        </Stack>
                      </FormControl>
                      <FormControl>
                        <FormControl>
                          <FormLabel
                            id="demo-controlled-radio-buttons-group"
                            className="label__title filter_category_title"
                          >
                            Exp Month
                          </FormLabel>
                          <Stack direction="row" flexWrap="wrap">
                            {exp_month_options.map((item, i) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    value={item}
                                    onChange={handle_change_exp_month}
                                    checked={exp_month.includes(item)}
                                    size="small"
                                    sx={{
                                      color: "white",
                                      "&.Mui-checked": {
                                        color: "#28cab3",
                                      },
                                    }}
                                  />
                                }
                                label={item}
                                key={i}
                              />
                            ))}

                            <FormControlLabel
                              control={
                                <Checkbox
                                  value="all"
                                  onChange={handle_change_exp_month}
                                  checked={isAllselectedExpMonth}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label="All"
                            />
                          </Stack>
                        </FormControl>
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title filter_category_title"
                        >
                          Exp Year
                        </FormLabel>
                        <Stack direction="row" flexWrap="wrap">
                          {exp_year_options.map((item, i) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item}
                                  onChange={handle_change_exp_year}
                                  checked={exp_year.includes(item)}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={item}
                              key={i}
                            />
                          ))}

                          <FormControlLabel
                            control={
                              <Checkbox
                                value="all"
                                onChange={handle_change_exp_year}
                                checked={isAllselectedExpYear}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="All"
                          />
                        </Stack>
                      </FormControl>
                    </div>
                  )}
                  {graph_type === "crypto" && (
                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title filter_category_title"
                      >
                        Crypto Provider
                      </FormLabel>
                      <Stack direction="row" flexWrap="wrap">
                        {crypto_options.map((item, id) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={item}
                                onChange={handle_change_crypto_provider}
                                checked={crypto_provider.includes(item)}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={<span className="card_name">{item}</span>}
                            key={id}
                          />
                        ))}

                        <FormControlLabel
                          control={
                            <Checkbox
                              value="all"
                              onChange={handle_change_crypto_provider}
                              checked={isAllselectedCryptoProvider}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="All"
                        />
                      </Stack>
                    </FormControl>
                  )}
                  {graph_type === "email" && (
                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title filter_category_title"
                      >
                        Email Provider
                      </FormLabel>
                      <Stack direction="row" flexWrap="wrap">
                        {email_options.map((item, id) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={item}
                                onChange={handle_change_email_provider}
                                checked={email_provider.includes(item)}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={<span className="card_name">{item}</span>}
                            key={id}
                          />
                        ))}

                        <FormControlLabel
                          control={
                            <Checkbox
                              value="all"
                              onChange={handle_change_email_provider}
                              checked={isAllselectedEmailProvider}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="All"
                        />
                      </Stack>
                    </FormControl>
                  )}
                  <Categories
                    postCategory={postCategory}
                    setPostCategory={setPostCategory}
                    container_type={"row"}
                  />
                </div>
              )}
              <button type="submit" className="button_global_style">
                Search
              </button>
            </form>
          )}
        </Formik>
      </div>
    </ClickAwayListener>
  );
}
ChartFiltersModal.propTypes = {
  set_open_modal: PropTypes.func,
  chart_type: PropTypes.string,
  chart_stats: PropTypes.object,
  set_chart_stats: PropTypes.func,
  stats_name: PropTypes.string,
  alert_type: PropTypes.string,
  alert_id: PropTypes.number,
  graph_type: PropTypes.string,
  postCategory: PropTypes.array,
  setPostCategory: PropTypes.func,
  stealer_multiple_obj: PropTypes.object,
  set_stealer_multiple_obj: PropTypes.func,
  category_stealer: PropTypes.string,
  set_category_stealer: PropTypes.func,
  keyword: PropTypes.string,
  setKeyword: PropTypes.func,
  post_search_sub: PropTypes.string,
  set_search_sub: PropTypes.func,
  card_type: PropTypes.array,
  set_card_type: PropTypes.func,
  crypto_provider: PropTypes.array,
  set_crypto_provider: PropTypes.func,
  email_provider: PropTypes.array,
  set_email_provider: PropTypes.func,
  exp_year: PropTypes.array,
  set_exp_year: PropTypes.func,
  exp_month: PropTypes.array,
  set_exp_month: PropTypes.func,
};
export default memo(ChartFiltersModal);
