import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IconContext } from "react-icons";
import {
  FaClock,
  FaCloudDownloadAlt,
  FaEye,
  FaImage,
  FaLink,
  FaReplyAll,
  FaSyncAlt,
  FaUserEdit,
  FaUsers,
  FaVideo,
} from "react-icons/fa";
import { Stack } from "@mui/material";
import LineChart from "../TableUi/components/sections/Charts/LineChart";
import Chart from "react-apexcharts";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import fileDownload from "js-file-download";
import { date_fm, get_token_front } from "../actions/userAction";
import { handle_session_get } from "../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../utils/Validation/Token/token_checker";
import { handle_error_message } from "../utils/ResponseMessage/response_message";
import { styles_background_img } from "../utils/Globals/global_functions";
import TransparentContainer from "../TableUi/components/sections/Charts/TransparentContainer";
import { ToastContainer } from "react-toastify";
import {
  StatsErrorMsg,
  RollerAnimation,
} from "../TableUi/components/loader/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import { handleOpenNewTab } from "../utils/Globals/global_functions";
import { link_website } from "../Assets/globalfunctions";

function Individualuser({ users, date_fm, get_token_front }) {
  const token = handle_session_get("Token");
  const [user_upload_group_info, set_user_upload_group_info] = useState(null);
  const [user_upload_profile, set_user_upload_profile] = useState(null);
  const [calendar_filter, set_calendar_filter] = useState("day");
  const [calendar_value, set_calendar_value] = useState(null);
  const [calendar_date, set_calendar_date] = useState(null);
  const [all_data, set_all_data] = useState(null);
  const [forwarded_data, set_forwarded_data] = useState(null);
  const [replied_data, set_replied_data] = useState(null);
  const [user_count_data, set_user_count_data] = useState(null);
  const [graph_loading, set_graph_loading] = useState(false);
  const [pie_loading, set_pie_loading] = useState(false);
  const [pie_loading_loadmore, set_pie_loading_loadmore] = useState(false);
  const [group_loading, set_group_loading] = useState(false);
  const [forwarded_loading, set_forwarded_loading] = useState(false);
  const [reply_loading, set_reply_loading] = useState(false);
  const [activity_value, set_activity_value] = useState(null);
  const [activity_date, set_activity_date] = useState(null);
  const [activity_filter, set_activity_filter] = useState("day");
  const [user_id, set_user_id] = useState(null);
  const [user_detail, set_user_detail] = useState(null);
  const [verify_user, set_verify_user] = useState({
    loading: false,
    error: "",
  });
  const [unique_has_more_data, set_unique_has_more_data] = useState(false);
  // State for hourly Posts
  const [hourly_post_data, set_hourly_post_data] = useState([]);
  const [hourly_post_status, set_hourly_post_status] = useState({
    loading: false,
    error: "",
    error_status: false,
  });

  const [activity_loading, set_activity_loading] = useState(false);

  useEffect(() => {
    if (token !== null) {
      const url = window.location.href.toString();
      const url_split_userid = url.split("?")[1].split("page=")[1];
      const url_user_id = url_split_userid.trim() ?? url_split_userid;
      const userId = decodeURIComponent(url_user_id);
      const trimmedUserId = userId.trim();
      get_user_detail_verify(trimmedUserId);
    }
  }, []);
  const date_fm_filter = (dt) => {
    if (dt !== undefined) {
      const sp_dt = dt.split("-");
      const new_sp_month = `0${parseInt(sp_dt[1]) - 1}`;
      const date = new Date(sp_dt[0], new_sp_month, sp_dt[2]); // 2009-11-10
      const month = date.toLocaleString("default", { month: "long" });
      const mod_month = month.substring(0, 3);
      const ret_dt = `${sp_dt[2]} ${mod_month} ${sp_dt[0]}`;
      return ret_dt;
    }
    return false;
  };
  const post_img = (links, id) => {
    if (links.includes("t.me")) {
      if (links.includes("/s")) {
        const rep_link = links.split("/s/");
        const new_link = rep_link[rep_link.length - 1];

        let bck_link =
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
        if (id !== "None") {
          bck_link = `https://telegramdb.org/data/chats/photo/big/${id}.webp`;
        }

        return (
          <img
            className="img-fluid rounded-circle"
            src={`/static/images/${new_link}.jpg`}
            alt="User"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = bck_link;
            }}
          />
        );
      }
      const rep_link = links.split("t.me/");
      const new_link = rep_link[rep_link.length - 1];

      let bck_link =
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
      if (id !== "None") {
        bck_link = `https://telegramdb.org/data/chats/photo/big/${id}.webp`;
      }
      return (
        <img
          style={{
            width: "48px",
            height: "48px",
            padding: "2px",
            border: "2px solid #f4f4f4",
          }}
          className="img-fluid rounded-circle"
          src={`/static/images/${new_link}.jpg`}
          alt="User"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = bck_link;
          }}
        />
      );
    }
    let bck_link =
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
    if (id !== "None") {
      bck_link = `https://telegramdb.org/data/chats/photo/big/${id}.webp`;
    }
    return (
      <img
        style={{
          width: "48px",
          height: "48px",
          padding: "2px",
          border: "2px solid #f4f4f4",
        }}
        className="img-fluid rounded-circle"
        src={`/static/images/${links}.jpg`}
        alt="User"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = bck_link;
        }}
      />
    );
  };

  const handle_response_group_user_table = (res_type, res_status) => {
    const all_profile = res_type.map((val) => val.group_link);
    set_user_upload_group_info((prev) => {
      const remove_dublicate =
        prev !== null
          ? res_type.filter(
              (item) => !prev.some((val) => val.group_name === item.group_name),
            )
          : [];
      return res_status === "load_more"
        ? [...prev, ...remove_dublicate]
        : res_type;
    });
    set_user_upload_profile(all_profile);
  };
  const get_user_detail_verify = (user) => {
    set_verify_user({ loading: true, error: "" });
    const data = { qtext: user };
    axios
      .post("https://dev.recordedtelegram.com/verify_user", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const response_info = res.data.data;
        const verify_id = response_info.userid;
        set_user_detail({
          username: response_info.username,
          userfullname: response_info.userfullname,
          phone: response_info.phone,
          userid: response_info.userid,
          bot: response_info.bot,
          grouptitle: response_info.grouptitle,
          grouplink: response_info.grouplink ?? "None",
        });
        get_user_post_data(verify_id);
        get_calendar_data(verify_id, "daily");
        get_post_data(verify_id);
        get_forwarded_data(verify_id);
        get_replied_data(verify_id);
        get_calendar_stats(verify_id);
        get_count_data(verify_id);
        get_activity_data(verify_id);
        set_user_id(verify_id);
        get_graph_data_hourly(verify_id, "user", null);
        set_verify_user({ loading: false, error: "" });
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_verify_user({ loading: false, error: err.response.data.errormsg });
      });
  };
  const get_graph_data_hourly = (channel, type, sub_type = null) => {
    set_hourly_post_status((prev) => ({
      ...prev,
      loading: true,
      error: "",
      error_status: false,
    }));
    const data = { qtext: channel, search_type: type, sub_type };
    axios
      .post("https://dev.recordedtelegram.com/hourly-data", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const res_obj = res.data;
        const time_obj = Object.fromEntries(
          Object.entries(res_obj).sort(
            (a, b) => Number(a[0].split(":")[0]) - Number(b[0].split(":")[0]),
          ),
        );
        if (Object.keys(time_obj).length > 0) {
          const chart_data = [];
          for (const key in time_obj) {
            const key_value = {
              x: key,
              y: time_obj[key],
            };
            chart_data.push(key_value);
          }
          set_hourly_post_data(chart_data);
        } else {
          set_hourly_post_data([]);
        }
        set_hourly_post_status((prev) => ({
          ...prev,
          loading: false,
          error: "",
          error_status: false,
        }));
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_hourly_post_status((prev) => ({
          ...prev,
          loading: false,
          error: err.response.data.error,
          error_status: true,
        }));
      });
  };

  const get_user_post_data = (url_user_id) => {
    set_pie_loading(true);
    const data = { user_id: url_user_id };
    axios
      .post("https://dev.recordedtelegram.com/unique_user_data", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${get_token_front()}`,
        },
      })
      .then((res) => {
        const all_response = res.data.data.stats;
        const filter_response = all_response.filter(
          (item) => item.group_name !== "None",
        );
        set_unique_has_more_data(res.data.data.has_more_data);
        set_pie_loading(false);
        handle_response_group_user_table(filter_response, "load_first");
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_pie_loading(false);
        set_user_upload_group_info([]);
      });
  };

  const get_user_post_data_append = (url_user_id) => {
    set_pie_loading_loadmore(true);
    const data = { user_id: url_user_id };

    axios
      .post("https://dev.recordedtelegram.com/generate_external_group", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const all_response = res.data.data;
        const filter_response = all_response.filter(
          (item) => item.group_name !== "None",
        );
        set_unique_has_more_data(false);
        set_pie_loading_loadmore(false);
        handle_response_group_user_table(filter_response, "load_more");
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_pie_loading_loadmore(false);
        handle_error_message(err.response.data.errormsg);
      });
  };

  const get_calendar_data = (url_user_id, filters) => {
    set_graph_loading(true);

    const data = {
      qtext: url_user_id.toString(),
      extra_filter: "user_filter",
      dt_filter: filters,
    };

    axios
      .post("https://dev.recordedtelegram.com/group_date", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${get_token_front()}`,
        },
      })
      .then((res) => {
        const values = res.data.map((val) => val.doc_count);
        const date = res.data.map((val) => date_fm_filter(val.key_as_string));
        const new_date = date.sort((a, b) => new Date(a) - new Date(b));
        set_calendar_date(new_date.slice(0, 7));
        set_calendar_value(values.slice(0, 7));
        set_graph_loading(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_graph_loading(false);
        set_calendar_date([]);
      });
  };
  const get_activity_data = (url_user_id, filters) => {
    set_activity_loading(true);

    const data = {
      qtext: url_user_id.toString(),
      extra_filter: "user_filter",
      dt_filter: filters,
      sort_filter: "date",
    };

    axios
      .post("https://dev.recordedtelegram.com/group_date", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${get_token_front()}`,
        },
      })
      .then((res) => {
        const values = res.data.map((val) => val.doc_count);
        const date = res.data.map((val) => val.key_as_string);

        set_activity_date(date);
        set_activity_value(values);
        set_activity_loading(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_activity_date([]);
        set_activity_loading(false);
      });
  };
  const get_count_data = (url_user_id) => {
    const data = { user_id: url_user_id };

    axios
      .post("https://dev.recordedtelegram.com/get_user_count", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${get_token_front()}`,
        },
      })
      .then((res) => {
        set_user_count_data(res.data);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_user_count_data(null);
      });
  };
  const get_post_data = (url_user_id) => {
    set_group_loading(true);

    const data = { user_id: url_user_id };

    axios
      .post("https://dev.recordedtelegram.com/get_user_data", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${get_token_front()}`,
        },
      })
      .then((res) => {
        const sorted_data = [...sort_data_date(res.data)];
        set_all_data(sorted_data);
        set_group_loading(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_all_data([]);
        set_group_loading(false);
      });
  };
  const get_forwarded_data = (url_user_id) => {
    set_forwarded_loading(true);
    const data = { user_id: url_user_id, search_type: "forwarded" };
    axios
      .post("https://dev.recordedtelegram.com/get_user_data", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${get_token_front()}`,
        },
      })
      .then((res) => {
        const sorted_data = [...sort_data_date(res.data)];
        set_forwarded_data(sorted_data);
        set_forwarded_loading(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_forwarded_loading(false);
        set_forwarded_data([]);
      });
  };
  const get_replied_data = (url_user_id) => {
    set_reply_loading(true);

    const data = { user_id: url_user_id, search_type: "replied" };

    axios
      .post("https://dev.recordedtelegram.com/get_user_data", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${get_token_front()}`,
        },
      })
      .then((res) => {
        const sorted_data = [...sort_data_date(res.data)];
        set_replied_data(sorted_data);
        set_reply_loading(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_replied_data([]);
        set_reply_loading(false);
      });
  };

  const audience_filt = (data) => {
    if (data !== "null") {
      let new_data = data;
      try {
        const conv_data = data.replaceAll("'", '"');
        new_data = JSON.parse(conv_data);
      } catch {
        //Do nothing
      }

      if (typeof new_data === "object") {
        return (
          <small>
            <span>
              <i className="icon ion-md-time" />
              <div style={{ display: "inline" }}>
                <IconContext.Provider
                  value={{ size: 18, marginTop: "-10px", color: "#4EE2EC" }}
                >
                  <FaUsers />
                </IconContext.Provider>

                <span style={{ marginLeft: "5px" }}>
                  {new_data[0].subscribers}
                </span>
              </div>
              {new_data[0].photos ? (
                <div style={{ display: "inline", marginLeft: "4px" }}>
                  <IconContext.Provider
                    value={{ size: 18, marginTop: "-10px", color: "#FF7EA5" }}
                  >
                    <FaImage />
                  </IconContext.Provider>
                  <span style={{ marginLeft: "5px" }}>
                    {new_data[0].photos}
                  </span>
                </div>
              ) : null}
              {new_data[0].videos ? (
                <div style={{ display: "inline", marginLeft: "4px" }}>
                  <IconContext.Provider
                    value={{ size: 18, marginTop: "-10px", color: "#C388F6" }}
                  >
                    <FaVideo />
                  </IconContext.Provider>
                  <span style={{ marginLeft: "5px" }}>
                    {new_data[0].videos}
                  </span>
                </div>
              ) : null}
              {new_data[0].links ? (
                <div style={{ display: "inline", marginLeft: "4px" }}>
                  <IconContext.Provider
                    value={{ size: 18, marginTop: "-10px", color: "#00FA9A" }}
                  >
                    <FaLink />
                  </IconContext.Provider>
                  <span style={{ marginLeft: "5px" }}>{new_data[0].links}</span>
                </div>
              ) : null}
            </span>
          </small>
        );
      }
      return (
        <small>
          <span>
            <i className="icon ion-md-time" />
            <div style={{ display: "inline" }}>
              <IconContext.Provider
                value={{ size: 18, marginTop: "-10px", color: "#4EE2EC" }}
              >
                <FaUsers />
              </IconContext.Provider>

              <span style={{ marginLeft: "5px" }}>{data}</span>
            </div>
          </span>
        </small>
      );
    }
  };
  const get_calendar_stats = (url_user_id) => {
    const data = { qtext: url_user_id.toString(), extra_filter: "user_filter" };
    axios
      .post("https://dev.recordedtelegram.com/group_date_stats", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {})
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
      });
  };
  const user_post_download = (search_type = null) => {
    document.getElementById("showdownload").className = "modal fade show";
    document.getElementById("showdownload").style.display = "block";
    const data = { userid: user_id, search_type };

    axios
      .post("https://dev.recordedtelegram.com/user_id_csv_report", data, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${get_token_front()}`,
        },
      })
      .then((res) => {
        let file_type = search_type;
        if (search_type === null) {
          file_type = "Posts";
        }
        const file_name = `${user_id}_${file_type}.csv`;
        fileDownload(res.data, file_name);
        document.getElementById("showdownload").className = "modal fade";
        document.getElementById("showdownload").style.display = "none";
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        document.getElementById("showdownload").className = "modal fade";
        document.getElementById("showdownload").style.display = "none";
        handle_error_message("Sorry could not Download the file");
      });
  };

  const sort_data_date = (data) => {
    let sorted_data = [];
    try {
      sorted_data = data.sort(
        (a, b) => new Date(b._source.date) - new Date(a._source.date),
      );
    } catch {
      sorted_data = data;
    }
    return sorted_data;
  };
  const filter_group_info_stats = (stats_array, filter_type) => {
    try {
      const filter_stats_arr = stats_array.filter(
        (item) => item.group_total_data !== "None",
      );
      if (filter_type === "labels") {
        const labels_array = filter_stats_arr.map((item) => item.group_name);
        return labels_array;
      } else {
        const values_array = filter_stats_arr.map(
          (item) => item.group_total_data,
        );
        return values_array;
      }
    } catch {
      return [];
    }
  };

  return (
    <div className="company_stats_main_container" style={styles_background_img}>
      <div
        style={{ margin: "100px auto 0 auto" }}
        className="user_stats_container_main"
      >
        {verify_user.loading === true ? (
          <RollerAnimation />
        ) : verify_user.error ? (
          <StatsErrorMsg error={verify_user.error} />
        ) : (
          <>
            <TransparentContainer
              container={
                <div className="row">
                  <div
                    className="card"
                    style={{
                      background: "transparent",
                      color: "white",
                      width: "98.5%",
                      marginLeft: "10px",
                    }}
                  >
                    <div className="card-body">
                      <div className="user-profile">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-xl-4">
                            <div className="main-profile-container d-sm-flex">
                              <div className="profile-img">
                                <img
                                  src="https://img.icons8.com/bubbles/100/000000/user.png"
                                  alt="user"
                                />
                              </div>
                              <div className="profile-text">
                                <h4>
                                  {user_detail !== null && user_detail.username
                                    ? user_detail.username
                                    : "..."}
                                </h4>
                                <h6 className="text-muted mb-3">
                                  User ID: {user_id !== null ? user_id : "..."}
                                </h6>
                                <h6 className="text-muted mb-3">
                                  User FullName:{" "}
                                  {user_detail !== null
                                    ? user_detail.userfullname
                                      ? user_detail.userfullname
                                      : "None"
                                    : "..."}
                                </h6>
                                <h6 className="text-muted mb-3">
                                  User Phone:{" "}
                                  {user_detail !== null
                                    ? user_detail.phone
                                      ? user_detail.phone
                                      : "None"
                                    : "..."}
                                </h6>
                                <h6 className="text-muted mb-3">
                                  Bot:{" "}
                                  {user_detail !== null
                                    ? user_detail.bot
                                      ? user_detail.bot
                                      : "None"
                                    : "..."}
                                </h6>
                                <Stack direction="row" alignItems="baseline">
                                  <h6 className="text-muted mb-3">
                                    User Group:
                                  </h6>
                                  {user_detail !== null ? (
                                    user_detail.grouptitle ? (
                                      user_detail.grouptitle !== "None" &&
                                      user_detail.grouplink !== "None" ? (
                                        <button
                                          onClick={() =>
                                            link_website(user_detail.grouplink)
                                          }
                                          className="user_redirect_btn"
                                        >
                                          {user_detail.grouptitle}
                                        </button>
                                      ) : (
                                        <h6 className="text-muted ">None</h6>
                                      )
                                    ) : (
                                      <h6 className="text-muted ">None</h6>
                                    )
                                  ) : (
                                    "..."
                                  )}
                                </Stack>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-xl-7">
                            <div className="user-icon">
                              <div className="user-icon-container">
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="mr-3">
                                      <div
                                        style={{
                                          padding: "1rem",
                                          marginBottom: "0px",
                                          boxShadow: "none",
                                        }}
                                        className="card no-shadow rm-border bg-transparent widget-chart text-left"
                                      >
                                        <div className="icon-wrapper rounded-circle">
                                          <div
                                            style={{ background: "#85C1E9" }}
                                            className="icon-wrapper-bg opacity-10 "
                                          />
                                          <i
                                            style={{
                                              zIndex: "10",
                                              marginLeft: "12px",
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                size: 30,
                                                marginTop: "10px",
                                                marginLeft: "12px",
                                                color: "white",
                                              }}
                                            >
                                              <FaUsers />
                                            </IconContext.Provider>
                                          </i>
                                        </div>
                                        <div className="widget-chart-content">
                                          <div className="widget-subheading">
                                            No of Group
                                          </div>
                                          <div className="widget-numbers">
                                            {user_upload_group_info !== null
                                              ? user_upload_group_info.length
                                              : "..."}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="mr-3">
                                      <div
                                        style={{
                                          padding: "1rem",
                                          marginBottom: "0px",
                                          boxShadow: "none",
                                        }}
                                        className="card no-shadow rm-border bg-transparent widget-chart text-left"
                                      >
                                        <div className="icon-wrapper rounded-circle">
                                          <div
                                            style={{ background: "#45B39D" }}
                                            className="icon-wrapper-bg opacity-10 "
                                          />
                                          <i
                                            style={{
                                              zIndex: "10",
                                              marginLeft: "12px",
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                size: 30,
                                                marginTop: "10px",
                                                marginLeft: "12px",
                                                color: "white",
                                              }}
                                            >
                                              <FaUserEdit />
                                            </IconContext.Provider>
                                          </i>
                                        </div>
                                        <div className="widget-chart-content">
                                          <div className="widget-subheading">
                                            Group/Channel Post
                                          </div>
                                          <div className="widget-numbers">
                                            {user_count_data !== null
                                              ? user_count_data.group
                                              : "..."}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="mr-3">
                                      <div
                                        style={{
                                          padding: "1rem",
                                          marginBottom: "0px",
                                          boxShadow: "none",
                                        }}
                                        className="card no-shadow rm-border bg-transparent widget-chart text-left"
                                      >
                                        <div className="icon-wrapper rounded-circle">
                                          <div
                                            style={{ background: "#F8C471" }}
                                            className="icon-wrapper-bg opacity-10 "
                                          />
                                          <i
                                            style={{
                                              zIndex: "10",
                                              marginLeft: "12px",
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                size: 30,
                                                marginTop: "10px",
                                                marginLeft: "12px",
                                                color: "white",
                                              }}
                                            >
                                              <FaSyncAlt />
                                            </IconContext.Provider>
                                          </i>
                                        </div>
                                        <div className="widget-chart-content">
                                          <div className="widget-subheading">
                                            Forwarded Post
                                          </div>
                                          <div className="widget-numbers">
                                            {user_count_data !== null
                                              ? user_count_data.forwarded
                                              : "..."}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="mr-0">
                                      <div
                                        style={{
                                          padding: "1rem",
                                          marginBottom: "0px",
                                          boxShadow: "none",
                                        }}
                                        className="card no-shadow rm-border bg-transparent widget-chart text-left"
                                      >
                                        <div className="icon-wrapper rounded-circle">
                                          <div
                                            style={{ background: "#d92550" }}
                                            className="icon-wrapper-bg opacity-10 "
                                          />
                                          <i
                                            style={{
                                              zIndex: "10",
                                              marginLeft: "12px",
                                            }}
                                          >
                                            <IconContext.Provider
                                              value={{
                                                size: 30,
                                                marginTop: "10px",
                                                marginLeft: "12px",
                                                color: "white",
                                              }}
                                            >
                                              <FaReplyAll />
                                            </IconContext.Provider>
                                          </i>
                                        </div>
                                        <div className="widget-chart-content">
                                          <div className="widget-subheading">
                                            Replied Post
                                          </div>
                                          <div className="widget-numbers">
                                            {user_count_data !== null
                                              ? user_count_data.replied
                                              : "..."}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
            <div className="mt-4">
              <LineChart
                chart_status={hourly_post_status}
                chart_data={hourly_post_data}
                chart_type="user"
              />
            </div>
            <div className="row mt-4">
              <div className="col-lg-6 col-xl-6 col-sm-12">
                <TransparentContainer
                  heading={"User Activity Graph"}
                  container={
                    <div>
                      <div className="widget-chart p-0">
                        {activity_loading ? (
                          <div
                            className="card-body scroll-custom"
                            style={{ height: "450px" }}
                          >
                            {" "}
                            <div className="blocks list-tab">
                              <div className="block orange" />
                              <div className="block blue" />
                            </div>
                            <h6 style={{ marginTop: "-25px" }}>
                              Data may take time to load
                            </h6>
                          </div>
                        ) : activity_value !== null &&
                          activity_date !== null ? (
                          activity_date.length === 0 ? (
                            <div
                              className="card-body"
                              style={{ height: "450px" }}
                            >
                              <h2 style={{ marginTop: "100px" }}>
                                No Data to Display
                              </h2>
                            </div>
                          ) : (
                            <div id="chart">
                              <Chart
                                options={{
                                  chart: {
                                    height: 350,
                                    type: "line",
                                    zoom: {
                                      enabled: false,
                                    },
                                    toolbar: {
                                      show: false,
                                    },
                                  },
                                  dataLabels: {
                                    enabled: false,
                                  },
                                  stroke: {
                                    curve: "straight",
                                    width: 2,
                                  },
                                  colors:
                                    activity_filter === "week"
                                      ? ["#16d9e3"]
                                      : activity_filter === "month"
                                      ? ["#EC6D10"]
                                      : activity_filter === "year"
                                      ? ["#3ac47d"]
                                      : ["#78c1ff"],

                                  grid: {
                                    show: false,
                                  },
                                  xaxis: {
                                    categories: activity_date,
                                    type: "datetime",
                                    labels: {
                                      style: {
                                        colors: "white",
                                        fontSize: "12px",
                                      },
                                      format:
                                        activity_filter === "week"
                                          ? "dd/MM/yyyy"
                                          : activity_filter === "month"
                                          ? "MM/yyyy"
                                          : activity_filter === "year"
                                          ? "dd/MM/yyyy"
                                          : "dd/MM/yyyy",
                                    },
                                  },
                                  yaxis: {
                                    labels: {
                                      style: {
                                        colors: "#fff",
                                        fontSize: "12px",
                                      },
                                    },
                                  },
                                }}
                                series={[
                                  {
                                    name: "post upload",
                                    data: activity_value,
                                  },
                                ]}
                                type="line"
                                height={450}
                              />
                            </div>
                          )
                        ) : (
                          <div
                            className="card-body"
                            style={{ height: "450px" }}
                          >
                            <h2 style={{ marginTop: "100px" }}>
                              No Data to Display
                            </h2>
                          </div>
                        )}
                      </div>
                      <div className="grid-menu grid-menu-2col">
                        <div className="no-gutters row">
                          <div className="p-2 col-sm-3">
                            <button
                              style={
                                activity_filter === "day"
                                  ? { background: "#78c1ff", color: " #fff" }
                                  : {
                                      color: "#78c1ff",
                                      borderColor: " #78c1ff",
                                    }
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                set_activity_filter("day");
                                get_activity_data(user_id, "day");
                              }}
                              className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-one"
                            >
                              {" "}
                              Day
                            </button>
                          </div>
                          <div className="p-2 col-sm-3">
                            <button
                              style={
                                activity_filter === "week"
                                  ? { background: "#16d9e3", color: " #fff" }
                                  : {
                                      color: "#16d9e3",
                                      borderColor: " #16d9e3",
                                    }
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                set_activity_filter("week");
                                get_activity_data(user_id, "week");
                              }}
                              className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-two"
                            >
                              {" "}
                              Weekly
                            </button>
                          </div>
                          <div className="p-2 col-sm-3">
                            <button
                              style={
                                activity_filter === "month"
                                  ? { background: "#EC6D10", color: " #fff" }
                                  : {
                                      color: "#EC6D10",
                                      borderColor: " #EC6D10",
                                    }
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                set_activity_filter("month");
                                get_activity_data(user_id, "month");
                              }}
                              className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-three"
                            >
                              {" "}
                              Monthly
                            </button>
                          </div>
                          <div className="p-2 col-sm-3">
                            <button
                              style={
                                activity_filter === "year"
                                  ? { background: "#3ac47d", color: " #fff" }
                                  : {
                                      color: "#3ac47d",
                                      borderColor: " #3ac47d",
                                    }
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                set_activity_filter("year");
                                get_activity_data(user_id, "year");
                              }}
                              className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-four"
                            >
                              {" "}
                              Yearly
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12  new-format">
                <TransparentContainer
                  heading={`
            ${
              calendar_filter === "week"
                ? "Weekly"
                : calendar_filter === "month"
                ? "Monthly"
                : calendar_filter === "year"
                ? "Yearly"
                : "Daily"
            } Post Update`}
                  container={
                    <div>
                      <div className="widget-chart p-0">
                        {graph_loading ? (
                          <div
                            className="card-body scroll-custom"
                            style={{ height: "450px" }}
                          >
                            {" "}
                            <div className="blocks list-tab">
                              <div className="block orange" />
                              <div className="block blue" />
                            </div>
                            <h6 style={{ marginTop: "-25px" }}>
                              Data may take time to load
                            </h6>
                          </div>
                        ) : calendar_value !== null &&
                          calendar_date !== null ? (
                          calendar_date.length === 0 ? (
                            <div
                              className="card-body"
                              style={{ height: "450px" }}
                            >
                              <h2 style={{ marginTop: "100px" }}>
                                No Data to Display
                              </h2>
                            </div>
                          ) : (
                            <div
                              style={{ minHeight: "450px", paddingLeft: "5px" }}
                            >
                              <Chart
                                options={{
                                  chart: {
                                    height: 350,
                                    type: "bar",
                                    toolbar: {
                                      show: false,
                                    },
                                  },
                                  stroke: {
                                    width: 2,
                                  },
                                  plotOptions: {
                                    bar: {
                                      borderRadius: 10,
                                      dataLabels: {
                                        position: "top", // top, center, bottom
                                      },
                                    },
                                  },
                                  grid: { show: false },
                                  xaxis: {
                                    categories: calendar_date,
                                    labels: {
                                      style: {
                                        fontSize: "11px",
                                        fontFamily: "monospace",
                                        fontWeight: 400,
                                        colors: users.dark_theme
                                          ? [
                                              "#FF7EA5",
                                              "#00FA9A",
                                              "#4EE2EC",
                                              "#C388F6",
                                              "#FF7EA5",
                                              "#00FA9A",
                                              "#4EE2EC",
                                              "#C388F6",
                                              "#FF7EA5",
                                              "#00FA9A",
                                            ]
                                          : ["#304758"],
                                      },
                                    },
                                    position: "top",
                                    axisBorder: {
                                      show: false,
                                    },
                                    axisTicks: {
                                      show: false,
                                    },
                                    crosshairs: {
                                      fill: {
                                        type: "gradient",
                                        gradient: {
                                          colorFrom: "#16d9e3",
                                          colorTo: "#BED1E6",
                                          stops: [0, 100],
                                          opacityFrom: 0.4,
                                          opacityTo: 0.5,
                                        },
                                      },
                                    },
                                  },
                                  colors:
                                    calendar_filter === "week"
                                      ? ["#16d9e3"]
                                      : calendar_filter === "month"
                                      ? ["#EC6D10"]
                                      : calendar_filter === "year"
                                      ? ["#3ac47d"]
                                      : ["#78c1ff"],
                                  yaxis: {
                                    axisBorder: {
                                      show: false,
                                    },
                                    axisTicks: {
                                      show: false,
                                    },
                                    labels: {
                                      show: false,
                                      formatter(val) {
                                        return `${val}Post`;
                                      },
                                    },
                                  },
                                }}
                                series={[
                                  {
                                    name: "total",
                                    data: calendar_value,
                                  },
                                ]}
                                type="line"
                                height={450}
                              />
                            </div>
                          )
                        ) : (
                          <div
                            className="card-body"
                            style={{ height: "450px" }}
                          >
                            <h2 style={{ marginTop: "100px" }}>
                              No Data to Display
                            </h2>
                          </div>
                        )}
                      </div>
                      <div className="grid-menu grid-menu-2col">
                        <div className="no-gutters row">
                          <div className="p-2 col-sm-3">
                            <button
                              style={
                                calendar_filter === "day"
                                  ? { background: "#78c1ff", color: " #fff" }
                                  : {
                                      color: "#78c1ff",
                                      borderColor: " #78c1ff",
                                    }
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                set_calendar_filter("day");
                                get_calendar_data(user_id, "day");
                              }}
                              className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-one"
                            >
                              {" "}
                              Day
                            </button>
                          </div>
                          <div className="p-2 col-sm-3">
                            <button
                              style={
                                calendar_filter === "week"
                                  ? { background: "#16d9e3", color: " #fff" }
                                  : {
                                      color: "#16d9e3",
                                      borderColor: " #16d9e3",
                                    }
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                set_calendar_filter("week");
                                get_calendar_data(user_id, "week");
                              }}
                              className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-two"
                            >
                              {" "}
                              Weekly
                            </button>
                          </div>
                          <div className="p-2 col-sm-3">
                            <button
                              style={
                                calendar_filter === "month"
                                  ? { background: "#EC6D10", color: " #fff" }
                                  : {
                                      color: "#EC6D10",
                                      borderColor: " #EC6D10",
                                    }
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                set_calendar_filter("month");
                                get_calendar_data(user_id, "month");
                              }}
                              className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-three"
                            >
                              {" "}
                              Monthly
                            </button>
                          </div>
                          <div className="p-2 col-sm-3">
                            <button
                              style={
                                calendar_filter === "year"
                                  ? { background: "#3ac47d", color: " #fff" }
                                  : {
                                      color: "#3ac47d",
                                      borderColor: " #3ac47d",
                                    }
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                set_calendar_filter("year");
                                get_calendar_data(user_id, "year");
                              }}
                              className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-four"
                            >
                              {" "}
                              Yearly
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6 new-format">
                <TransparentContainer
                  heading="Group Post Graph by the User"
                  container={
                    pie_loading ? (
                      <div
                        className="card-body scroll-custom"
                        style={{ height: "350px" }}
                      >
                        {" "}
                        <div className="blocks list-tab">
                          <div className="block orange" />
                          <div className="block blue" />
                        </div>
                        <h6 style={{ marginTop: "-25px" }}>
                          Data may take time to load
                        </h6>
                      </div>
                    ) : user_upload_group_info !== null &&
                      user_upload_group_info ? (
                      user_upload_group_info.length === 0 ? (
                        <div className="card-body" style={{ height: "350px" }}>
                          <h2 style={{ marginTop: "100px" }}>
                            No Data to Display
                          </h2>
                        </div>
                      ) : (
                        <div className="card-body" style={{ height: "350px" }}>
                          <div>
                            <Chart
                              options={{
                                chart: {
                                  type: "donut",
                                },
                                stroke: {
                                  show: false,
                                },
                                labels:
                                  filter_group_info_stats(
                                    user_upload_group_info,
                                    "labels",
                                  ) || [],
                                colors: [
                                  "#ec546c",
                                  "#d664c1",
                                  "#28c76f",
                                  "#26b5d3",
                                  "#8c7ae8",
                                ],
                                legend: {
                                  show: false,
                                },
                                responsive: [
                                  {
                                    breakpoint: 480,
                                    options: {
                                      chart: {
                                        width: 200,
                                      },
                                      legend: {
                                        show: false,
                                      },
                                    },
                                  },
                                ],
                              }}
                              series={
                                filter_group_info_stats(
                                  user_upload_group_info,
                                  "values",
                                ) || []
                              }
                              type="donut"
                              height={300}
                            />
                          </div>
                        </div>
                      )
                    ) : null
                  }
                />
              </div>
              <div className="col-md-6 new-format">
                <TransparentContainer
                  heading="Group Post Table by the User"
                  container={
                    pie_loading ? (
                      <div
                        className="card-body scroll-custom"
                        style={{ height: "350px" }}
                      >
                        {" "}
                        <div className="blocks list-tab">
                          <div className="block orange" />
                          <div className="block blue" />
                        </div>
                        <h6 style={{ marginTop: "-25px" }}>
                          Data may take time to load
                        </h6>
                      </div>
                    ) : user_upload_group_info !== null ? (
                      user_upload_group_info.length === 0 ? (
                        <div className="card-body" style={{ height: "350px" }}>
                          <h2 style={{ marginTop: "100px" }}>
                            No Data to Display
                          </h2>
                        </div>
                      ) : (
                        <div
                          className="card-body scroll-custom"
                          style={{ height: "350px" }}
                        >
                          {user_upload_group_info.map((val, i) => (
                            <div className="file-tab" key={i}>
                              <div className="media">
                                <div className="media-aside mr-1 align-self-start">
                                  {user_upload_profile !== null ? (
                                    user_upload_profile !== "None" ? (
                                      post_img(user_upload_profile, "None")
                                    ) : (
                                      <img
                                        style={{
                                          width: "48px",
                                          height: "48px",
                                          padding: "2px",
                                          border: "2px solid #f4f4f4",
                                        }}
                                        className="img-fluid rounded-circle"
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                        alt="User"
                                      />
                                    )
                                  ) : (
                                    "..."
                                  )}
                                </div>
                                <div className="media-body my-auto ">
                                  <div className="file-name ">
                                    <button
                                      onClick={(e) => {
                                        if (val.has_data === true) {
                                          handleOpenNewTab(
                                            `/page?page=${val.group_name}&type=channel name`,
                                            e,
                                          );
                                        } else {
                                          link_website(val.group_link);
                                        }
                                      }}
                                      className="group_channel_profile_link_btn text-start"
                                    >
                                      {val.group_name}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                {val.group_total_data !== "None" ? (
                                  <span className="font-weight-bold text-body-heading mr-1">
                                    Total Post Update:{" "}
                                    {user_upload_group_info !== null
                                      ? val.group_total_data
                                      : "..."}
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          ))}
                          {unique_has_more_data === true ? (
                            <div className="group_post_table_btn">
                              {pie_loading_loadmore === false ? (
                                <button
                                  onClick={() => {
                                    get_user_post_data_append(user_id);
                                  }}
                                >
                                  Load More
                                </button>
                              ) : (
                                <CircularProgress
                                  fontSize="small"
                                  sx={{ color: "#28cab3" }}
                                />
                              )}
                            </div>
                          ) : null}
                        </div>
                      )
                    ) : null
                  }
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-4">
                <TransparentContainer
                  container={
                    <div style={{ height: "500px" }}>
                      <div className="flex-content">
                        <div
                          className="flex-header"
                          style={{
                            background: "transparent",
                            color: "#dcf3ff",
                          }}
                        >
                          <div
                            className="title-header"
                            style={{
                              background: "transparent",
                              color: "#dcf3ff",
                            }}
                          >
                            <div className="header-icon">
                              <div className="media-aside mr-1 align-self-start">
                                <i className="file-icon lbg-3">
                                  <IconContext.Provider
                                    value={{
                                      size: 18,
                                      marginTop: "10px",
                                      marginLeft: "12px",
                                      color: "white",
                                    }}
                                  >
                                    <FaUserEdit />
                                  </IconContext.Provider>
                                </i>
                              </div>
                            </div>
                            <h3 className="header-tittle">
                              <span className="stats_title_color">
                                Post Upload
                              </span>
                              <span
                                className="header-small"
                                style={{ float: "right", marginLeft: "1.5rem" }}
                              >
                                {all_data != null && all_data.length > 0 ? (
                                  <button
                                    className="round-icon"
                                    style={{
                                      padding: "2px 13px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      user_post_download();
                                    }}
                                  >
                                    <IconContext.Provider value={{ size: 24 }}>
                                      <FaCloudDownloadAlt />
                                    </IconContext.Provider>
                                    <span
                                      style={{
                                        marginLeft: "10px",
                                        textTransform: "none",
                                      }}
                                    >
                                      Download
                                    </span>
                                  </button>
                                ) : null}
                              </span>
                            </h3>
                          </div>
                        </div>
                        {group_loading ? (
                          <div
                            className="card-body"
                            style={{ height: "400px", width: "100%" }}
                          >
                            {" "}
                            <div className="blocks list-tab">
                              <div className="block orange" />
                              <div className="block blue" />
                            </div>
                            <h6 style={{ marginTop: "-25px" }}>
                              Data may take time to load
                            </h6>
                          </div>
                        ) : all_data !== null ? (
                          all_data.length > 1 ? (
                            all_data.map((val, i) => (
                              <div key={i} className="flex-body">
                                <div className="flex-body-conatiner">
                                  <div className="flex-indv-container">
                                    <div className="flex-indv-head-body">
                                      <div className="indv-head">
                                        <div className="header-content">
                                          <div className="profile-img">
                                            <div className="media m-0">
                                              <div className="d-flex mr-3">
                                                {user_upload_profile !==
                                                null ? (
                                                  user_upload_profile !==
                                                  "None" ? (
                                                    post_img(
                                                      user_upload_profile,
                                                      "None",
                                                    )
                                                  ) : (
                                                    <img
                                                      className="img-fluid rounded-circle"
                                                      style={{
                                                        width: "48px",
                                                        height: "48px",
                                                        padding: "2px",
                                                        border:
                                                          "2px solid #f4f4f4",
                                                      }}
                                                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                                      alt="User"
                                                    />
                                                  )
                                                ) : (
                                                  "..."
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="profile-title">
                                            <div className="media-body">
                                              <div>
                                                <button
                                                  onClick={(e) =>
                                                    handleOpenNewTab(
                                                      `/page?page=${val._source.conv_name}&type=channel name`,
                                                      e,
                                                    )
                                                  }
                                                  className="group_channel_profile_link_btn"
                                                >
                                                  {val._source.conv_name}
                                                </button>
                                              </div>
                                              {val._source.is_group ===
                                              "False" ? (
                                                audience_filt(
                                                  val.participants_count,
                                                )
                                              ) : val.is_group === "True" ? (
                                                <small>
                                                  <span>
                                                    <i className="icon ion-md-time" />
                                                    <div
                                                      style={{
                                                        display: "inline",
                                                      }}
                                                    >
                                                      <IconContext.Provider
                                                        value={{
                                                          size: 18,
                                                          marginTop: "-10px",
                                                          color: "#4EE2EC",
                                                        }}
                                                      >
                                                        <FaUsers />
                                                      </IconContext.Provider>

                                                      <span
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        {val._source.participants_count.includes(
                                                          "members",
                                                        )
                                                          ? val._source.participants_count
                                                              .split(
                                                                " members",
                                                              )[0]
                                                              .replace(",", "")
                                                              .length > 3
                                                            ? `${val._source.participants_count
                                                                .split(
                                                                  " members",
                                                                )[0]
                                                                .replace(
                                                                  ",",
                                                                  "",
                                                                )
                                                                .substring(
                                                                  0,
                                                                  val._source.participants_count
                                                                    .split(
                                                                      " members",
                                                                    )[0]
                                                                    .replace(
                                                                      ",",
                                                                      "",
                                                                    ).length -
                                                                    3,
                                                                )}K`
                                                            : val.participants_count
                                                                .split(
                                                                  " members",
                                                                )[0]
                                                                .replace(
                                                                  ",",
                                                                  "",
                                                                )
                                                          : val._source
                                                              .participants_count
                                                              .length > 3
                                                          ? `${val._source.participants_count.substring(
                                                              0,
                                                              val._source
                                                                .participants_count
                                                                .length - 3,
                                                            )}K`
                                                          : val._source
                                                              .participants_count}
                                                      </span>
                                                    </div>
                                                  </span>
                                                </small>
                                              ) : (
                                                <small>
                                                  <span>
                                                    <i className="icon ion-md-time" />{" "}
                                                    <FontAwesomeIcon
                                                      style={{
                                                        color: "#00BFFF",
                                                      }}
                                                      icon={faClock}
                                                      size="1x"
                                                    />{" "}
                                                    {date_fm(val._source.date)}
                                                  </span>
                                                </small>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="indv-body">
                                        <div className="profile-text">
                                          {val._source.message}{" "}
                                        </div>
                                      </div>
                                      <div className="indv-footer">
                                        <div className="footer-icon">
                                          <IconContext.Provider
                                            value={{
                                              size: 18,
                                              color: "#a0a6c2",
                                            }}
                                          >
                                            <FaClock />
                                          </IconContext.Provider>
                                          <span>
                                            {date_fm(val._source.date)}
                                          </span>
                                        </div>
                                        <div className="footer-icon">
                                          <IconContext.Provider
                                            value={{
                                              size: 18,
                                              color: "#a0a6c2",
                                            }}
                                          >
                                            <FaEye />
                                          </IconContext.Provider>
                                          <span>
                                            {val._source.views !== "None"
                                              ? val._source.views
                                              : 0}
                                          </span>
                                        </div>
                                        <div className="footer-icon">
                                          <IconContext.Provider
                                            value={{
                                              size: 18,
                                              color: "#a0a6c2",
                                            }}
                                          >
                                            <FaSyncAlt />
                                          </IconContext.Provider>
                                          <span>
                                            {val._source.reply !== "None"
                                              ? "1"
                                              : "0"}
                                          </span>
                                        </div>
                                        <div className="footer-icon">
                                          <IconContext.Provider
                                            value={{
                                              size: 18,
                                              color: "#a0a6c2",
                                            }}
                                          >
                                            <FaReplyAll />
                                          </IconContext.Provider>
                                          <span>
                                            {val._source.forwardedfromchanid !==
                                            "None"
                                              ? "1"
                                              : "0"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              className="card-body"
                              style={{ height: "400px" }}
                            >
                              <h2 style={{ marginTop: "100px" }}>
                                No Data to Display
                              </h2>
                            </div>
                          )
                        ) : null}
                      </div>
                    </div>
                  }
                />
              </div>
              <div className="col-md-4">
                <TransparentContainer
                  container={
                    <div style={{ height: "500px" }}>
                      <div className="flex-content">
                        <div
                          className="flex-header"
                          style={{
                            background: "transparent",
                            color: "#dcf3ff",
                          }}
                        >
                          <div
                            className="title-header"
                            style={{
                              background: "transparent",
                              color: "#dcf3ff",
                            }}
                          >
                            <div className="header-icon">
                              <div className="media-aside mr-1 align-self-start">
                                <i className="file-icon lbg-4">
                                  <IconContext.Provider
                                    value={{
                                      size: 18,
                                      marginTop: "10px",
                                      marginLeft: "12px",
                                      color: "white",
                                    }}
                                  >
                                    <FaSyncAlt />
                                  </IconContext.Provider>
                                </i>
                              </div>
                            </div>
                            <h3 className="header-tittle">
                              <span className="stats_title_color">
                                Forwarded Post
                              </span>
                              <span
                                className="header-small"
                                style={{ float: "right", marginLeft: "1.5rem" }}
                              >
                                {forwarded_data !== null &&
                                forwarded_data.length > 0 ? (
                                  <span
                                    className="round-icon"
                                    style={{
                                      padding: "2px 13px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      user_post_download("forwarded");
                                    }}
                                  >
                                    <IconContext.Provider value={{ size: 24 }}>
                                      <FaCloudDownloadAlt />
                                    </IconContext.Provider>
                                    <span
                                      style={{
                                        marginLeft: "10px",
                                        textTransform: "none",
                                      }}
                                    >
                                      Download
                                    </span>
                                  </span>
                                ) : null}
                              </span>
                            </h3>
                          </div>
                        </div>
                        {forwarded_loading ? (
                          <div
                            className="card-body scroll-custom"
                            style={{ height: "400px" }}
                          >
                            {" "}
                            <div className="blocks list-tab">
                              <div className="block orange" />
                              <div className="block blue" />
                            </div>
                            <h6 style={{ marginTop: "-25px" }}>
                              Data may take time to load
                            </h6>
                          </div>
                        ) : forwarded_data !== null ? (
                          forwarded_data.length > 0 ? (
                            forwarded_data.map((val, i) => (
                              <div key={i} className="flex-body">
                                <div className="flex-body-conatiner">
                                  <div className="flex-indv-container">
                                    <div className="flex-indv-head-body">
                                      <div className="indv-head">
                                        <div className="header-content">
                                          <div className="profile-img">
                                            <div className="media m-0">
                                              <div className="d-flex mr-3">
                                                {user_upload_profile !==
                                                null ? (
                                                  user_upload_profile !==
                                                  "None" ? (
                                                    post_img(
                                                      user_upload_profile,
                                                      "None",
                                                    )
                                                  ) : (
                                                    <img
                                                      style={{
                                                        width: "48px",
                                                        height: "48px",
                                                        padding: "2px",
                                                        border:
                                                          "2px solid #f4f4f4",
                                                      }}
                                                      className="img-fluid rounded-circle"
                                                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                                      alt="User"
                                                    />
                                                  )
                                                ) : (
                                                  "..."
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="profile-title">
                                            <div className="media-body">
                                              <div>
                                                <button
                                                  onClick={(e) =>
                                                    handleOpenNewTab(
                                                      `/page?page=${val._source.conv_name}&type=channel name`,
                                                      e,
                                                    )
                                                  }
                                                  className="group_channel_profile_link_btn"
                                                >
                                                  {val._source.conv_name}
                                                </button>
                                              </div>
                                              {val._source.is_group ===
                                              "False" ? (
                                                audience_filt(
                                                  val.participants_count,
                                                )
                                              ) : val.is_group === "True" ? (
                                                <small>
                                                  <span>
                                                    <i className="icon ion-md-time" />
                                                    <div
                                                      style={{
                                                        display: "inline",
                                                      }}
                                                    >
                                                      <IconContext.Provider
                                                        value={{
                                                          size: 18,
                                                          marginTop: "-10px",
                                                          color: "#4EE2EC",
                                                        }}
                                                      >
                                                        <FaUsers />
                                                      </IconContext.Provider>

                                                      <span
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        {val._source.participants_count.includes(
                                                          "members",
                                                        )
                                                          ? val._source.participants_count
                                                              .split(
                                                                " members",
                                                              )[0]
                                                              .replace(",", "")
                                                              .length > 3
                                                            ? `${val._source.participants_count
                                                                .split(
                                                                  " members",
                                                                )[0]
                                                                .replace(
                                                                  ",",
                                                                  "",
                                                                )
                                                                .substring(
                                                                  0,
                                                                  val._source.participants_count
                                                                    .split(
                                                                      " members",
                                                                    )[0]
                                                                    .replace(
                                                                      ",",
                                                                      "",
                                                                    ).length -
                                                                    3,
                                                                )}K`
                                                            : val.participants_count
                                                                .split(
                                                                  " members",
                                                                )[0]
                                                                .replace(
                                                                  ",",
                                                                  "",
                                                                )
                                                          : val._source
                                                              .participants_count
                                                              .length > 3
                                                          ? `${val._source.participants_count.substring(
                                                              0,
                                                              val._source
                                                                .participants_count
                                                                .length - 3,
                                                            )}K`
                                                          : val._source
                                                              .participants_count}
                                                      </span>
                                                    </div>
                                                  </span>
                                                </small>
                                              ) : (
                                                <small>
                                                  <span>
                                                    <i className="icon ion-md-time" />{" "}
                                                    <FontAwesomeIcon
                                                      style={{
                                                        color: "#00BFFF",
                                                      }}
                                                      icon={faClock}
                                                      size="1x"
                                                    />{" "}
                                                    {date_fm(val._source.date)}
                                                  </span>
                                                </small>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="indv-body">
                                        <div className="profile-text">
                                          {val._source.message}{" "}
                                        </div>
                                      </div>
                                      <div className="indv-footer">
                                        <div className="footer-icon">
                                          <IconContext.Provider
                                            value={{
                                              size: 18,
                                              color: "#a0a6c2",
                                            }}
                                          >
                                            <FaClock />
                                          </IconContext.Provider>
                                          <span>
                                            {date_fm(val._source.date)}
                                          </span>
                                        </div>
                                        <div className="footer-icon">
                                          <IconContext.Provider
                                            value={{
                                              size: 18,
                                              color: "#a0a6c2",
                                            }}
                                          >
                                            <FaEye />
                                          </IconContext.Provider>
                                          <span>
                                            {val._source.views !== "None"
                                              ? val._source.views
                                              : 0}
                                          </span>
                                        </div>
                                        <div className="footer-icon">
                                          <IconContext.Provider
                                            value={{
                                              size: 18,
                                              color: "#a0a6c2",
                                            }}
                                          >
                                            <FaSyncAlt />
                                          </IconContext.Provider>
                                          <span>
                                            {val._source.reply !== "None"
                                              ? "1"
                                              : "0"}
                                          </span>
                                        </div>
                                        <div className="footer-icon">
                                          <IconContext.Provider
                                            value={{
                                              size: 18,
                                              color: "#a0a6c2",
                                            }}
                                          >
                                            <FaReplyAll />
                                          </IconContext.Provider>
                                          <span>
                                            {val._source.forwardedfromchanid !==
                                            "None"
                                              ? "1"
                                              : "0"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              className="card-body"
                              style={{ height: "400px" }}
                            >
                              <h2 style={{ marginTop: "100px" }}>
                                No Data to Display
                              </h2>
                            </div>
                          )
                        ) : null}
                      </div>
                    </div>
                  }
                />
              </div>
              <div className="col-md-4">
                <TransparentContainer
                  container={
                    <div style={{ height: "500px" }}>
                      <div className="flex-content">
                        <div
                          className="flex-header"
                          style={{
                            background: "transparent",
                            color: "#dcf3ff",
                          }}
                        >
                          <div
                            className="title-header"
                            style={{
                              background: "transparent",
                              color: "#dcf3ff",
                            }}
                          >
                            <div className="header-icon">
                              <div className="media-aside mr-1 align-self-start">
                                <i className="file-icon lbg-11">
                                  <IconContext.Provider
                                    value={{
                                      size: 18,
                                      marginTop: "10px",
                                      marginLeft: "12px",
                                      color: "white",
                                    }}
                                  >
                                    <FaReplyAll />
                                  </IconContext.Provider>
                                </i>
                              </div>
                            </div>
                            <h3 className="header-tittle">
                              <span className="stats_title_color">
                                Replied Post
                              </span>
                            </h3>
                          </div>
                        </div>
                        {reply_loading ? (
                          <div
                            className="card-body scroll-custom"
                            style={{ height: "400px" }}
                          >
                            {" "}
                            <div className="blocks list-tab">
                              <div className="block orange" />
                              <div className="block blue" />
                            </div>
                            <h6 style={{ marginTop: "-25px" }}>
                              Data may take time to load
                            </h6>
                          </div>
                        ) : replied_data !== null ? (
                          replied_data.length > 1 ? (
                            replied_data.map((val, i) => (
                              <div key={i} className="flex-body">
                                <div className="flex-body-conatiner">
                                  <div className="flex-indv-container">
                                    <div className="flex-indv-head-body">
                                      <div className="indv-head">
                                        <div className="header-content">
                                          <div className="profile-img">
                                            <div className="media m-0">
                                              <div className="d-flex mr-3">
                                                {user_upload_profile !==
                                                null ? (
                                                  user_upload_profile !==
                                                  "None" ? (
                                                    post_img(
                                                      user_upload_profile,
                                                      "None",
                                                    )
                                                  ) : (
                                                    <img
                                                      style={{
                                                        width: "48px",
                                                        height: "48px",
                                                        padding: "2px",
                                                        border:
                                                          "2px solid #f4f4f4",
                                                      }}
                                                      className="img-fluid rounded-circle"
                                                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                                      alt="User"
                                                    />
                                                  )
                                                ) : (
                                                  "..."
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="profile-title">
                                            <div className="media-body">
                                              <div>
                                                <button
                                                  onClick={(e) =>
                                                    handleOpenNewTab(
                                                      `/page?page=${val._source.conv_name}&type=channel name`,
                                                      e,
                                                    )
                                                  }
                                                  className="group_channel_profile_link_btn"
                                                >
                                                  {val._source.conv_name}
                                                </button>
                                              </div>
                                              {val._source.is_group ===
                                              "False" ? (
                                                audience_filt(
                                                  val.participants_count,
                                                )
                                              ) : val.is_group === "True" ? (
                                                <small>
                                                  <span>
                                                    <i className="icon ion-md-time" />
                                                    <div
                                                      style={{
                                                        display: "inline",
                                                      }}
                                                    >
                                                      <IconContext.Provider
                                                        value={{
                                                          size: 18,
                                                          marginTop: "-10px",
                                                          color: "#4EE2EC",
                                                        }}
                                                      >
                                                        <FaUsers />
                                                      </IconContext.Provider>

                                                      <span
                                                        style={{
                                                          marginLeft: "5px",
                                                        }}
                                                      >
                                                        {val._source.participants_count.includes(
                                                          "members",
                                                        )
                                                          ? val._source.participants_count
                                                              .split(
                                                                " members",
                                                              )[0]
                                                              .replace(",", "")
                                                              .length > 3
                                                            ? `${val._source.participants_count
                                                                .split(
                                                                  " members",
                                                                )[0]
                                                                .replace(
                                                                  ",",
                                                                  "",
                                                                )
                                                                .substring(
                                                                  0,
                                                                  val._source.participants_count
                                                                    .split(
                                                                      " members",
                                                                    )[0]
                                                                    .replace(
                                                                      ",",
                                                                      "",
                                                                    ).length -
                                                                    3,
                                                                )}K`
                                                            : val.participants_count
                                                                .split(
                                                                  " members",
                                                                )[0]
                                                                .replace(
                                                                  ",",
                                                                  "",
                                                                )
                                                          : val._source
                                                              .participants_count
                                                              .length > 3
                                                          ? `${val._source.participants_count.substring(
                                                              0,
                                                              val._source
                                                                .participants_count
                                                                .length - 3,
                                                            )}K`
                                                          : val._source
                                                              .participants_count}
                                                      </span>
                                                    </div>
                                                  </span>
                                                </small>
                                              ) : (
                                                <small>
                                                  <span>
                                                    <i className="icon ion-md-time" />{" "}
                                                    <FontAwesomeIcon
                                                      style={{
                                                        color: "#00BFFF",
                                                      }}
                                                      icon={faClock}
                                                      size="1x"
                                                    />{" "}
                                                    {date_fm(val._source.date)}
                                                  </span>
                                                </small>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="indv-body">
                                        <div className="profile-text">
                                          {val._source.message}{" "}
                                        </div>
                                      </div>
                                      <div className="indv-footer">
                                        <div className="footer-icon">
                                          <IconContext.Provider
                                            value={{
                                              size: 18,
                                              color: "#a0a6c2",
                                            }}
                                          >
                                            <FaClock />
                                          </IconContext.Provider>
                                          <span>
                                            {date_fm(val._source.date)}
                                          </span>
                                        </div>
                                        <div className="footer-icon">
                                          <IconContext.Provider
                                            value={{
                                              size: 18,
                                              color: "#a0a6c2",
                                            }}
                                          >
                                            <FaEye />
                                          </IconContext.Provider>
                                          <span>
                                            {val._source.views !== "None"
                                              ? val._source.views
                                              : 0}
                                          </span>
                                        </div>
                                        <div className="footer-icon">
                                          <IconContext.Provider
                                            value={{
                                              size: 18,
                                              color: "#a0a6c2",
                                            }}
                                          >
                                            <FaSyncAlt />
                                          </IconContext.Provider>
                                          <span>
                                            {val._source.reply !== "None"
                                              ? "1"
                                              : "0"}
                                          </span>
                                        </div>
                                        <div className="footer-icon">
                                          <IconContext.Provider
                                            value={{
                                              size: 18,
                                              color: "#a0a6c2",
                                            }}
                                          >
                                            <FaReplyAll />
                                          </IconContext.Provider>
                                          <span>
                                            {val._source.forwardedfromchanid !==
                                            "None"
                                              ? "1"
                                              : "0"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              className="card-body"
                              style={{ height: "400px" }}
                            >
                              <h2 style={{ marginTop: "100px" }}>
                                No Data to Display
                              </h2>
                            </div>
                          )
                        ) : null}
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
            <div
              className="modal fade"
              id="showdownload"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="showdeletebutton"
              aria-hidden="true"
              style={{ background: "rgba(0, 0, 0, 0.5)" }}
            >
              <div
                className="modal-dialog modal-confirm"
                style={{ background: "#2b3c46", marginTop: "13rem" }}
              >
                <div className="modal-content dk-mode">
                  <div className="modal-header flex-column">
                    <h4
                      className="modal-title w-100"
                      style={{ fontSize: "18px", color: "#359daf" }}
                    >
                      Please Wait, The File is on Download Process ...
                    </h4>
                  </div>
                  <div className="modal-body" style={{ marginTop: "50px" }}>
                    <div
                      className="load-wrapper"
                      style={{ marginLeft: "200px" }}
                    >
                      <div
                        className="load-circle"
                        style={{ background: "#359daf" }}
                      />
                      <div
                        className="load-circle"
                        style={{ background: "#359daf" }}
                      />
                      <div
                        className="load-circle"
                        style={{ background: "#359daf" }}
                      >
                        {" "}
                      </div>
                      <div className="load-shadow" />
                      <div className="load-shadow" />
                      <div className="load-shadow" />
                      <span style={{ left: "2%", color: "#359daf" }}>
                        Downloading
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}
Individualuser.propTypes = {
  users: PropTypes.object,
  date_fm: PropTypes.func,
  get_token_front: PropTypes.func,
};

const mapStateToProps = (state) => ({
  users: state.users,
  stats: state.stats,
});
export default connect(mapStateToProps, { date_fm, get_token_front })(
  Individualuser,
);
