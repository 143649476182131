import axios from "axios";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_error_message } from "../../../utils/ResponseMessage/response_message";
import fileDownload from "js-file-download";

export const handle_maximize_div = (id_parent, id_chart) => {
  const get_div = document.getElementById(id_parent);
  get_div.className = "company_stats_chart_child";
  const get_child_div = document.getElementById(id_chart);
  get_child_div.className = "comp_stats_chart_maximize";
  const get_btn_min = document.getElementById(`${id_parent}_min_btn`);
  get_btn_min.style.display = "block";
  const get_btn_max = document.getElementById(`${id_parent}_max_btn`);
  get_btn_max.style.display = "none";
};

export const handle_minimize_div = (id_parent, id_chart) => {
  const get_div = document.getElementById(id_parent);
  get_div.className = "company_stats_chart_child_div";
  const get_child_div = document.getElementById(id_chart);
  get_child_div.className = "comp_stats_chart";
  const get_btn_min = document.getElementById(`${id_parent}_min_btn`);
  get_btn_min.style.display = "none";
  const get_btn_max = document.getElementById(`${id_parent}_max_btn`);
  get_btn_max.style.display = "block";
};

export const handle_close_div = (id) => {
  const get_div = document.getElementById(id);
  let opacity = 1;
  const fadeOut = () => {
    opacity -= 0.05;
    get_div.style.opacity = opacity.toFixed(2);

    if (opacity > 0) {
      requestAnimationFrame(fadeOut);
    } else {
      get_div.style.display = "none";
      get_div.style.opacity = 1;
    }
  };
  requestAnimationFrame(fadeOut);
};

export const handle_determine_maximize_minimize_chart = (
  categories,
  container_type,
  chart_type,
  chart_item,
) => {
  try {
    const user_name = localStorage.getItem("User");
    const get_localstorage_product_obj =
      localStorage.getItem("store_dashboard_stats") &&
      JSON.parse(localStorage.getItem("store_dashboard_stats"));
    if (get_localstorage_product_obj) {
      if (
        get_localstorage_product_obj?.["dashboard_stats"]?.[user_name]?.[
          chart_type
        ]?.[chart_item] !== undefined
      ) {
        const get_stats =
          get_localstorage_product_obj["dashboard_stats"][user_name][
            chart_type
          ][chart_item];
        if (container_type === "parent") {
          return get_stats === "max"
            ? "company_stats_chart_child"
            : "company_stats_chart_child_div";
        }
        return get_stats === "max"
          ? "comp_stats_chart_maximize"
          : "comp_stats_chart";
      }
    }
    // const categories_keys = Object.keys(categories[0]);
    // const x_axis_len = categories.length;
    // if (categories_keys.length >= 20 || x_axis_len >= 20) {
    //   if (container_type === "parent") {
    //     return "company_stats_chart_child";
    //   }
    //   return "comp_stats_chart_maximize";
    // }
    if (container_type === "parent") {
      return "company_stats_chart_child_div";
    }
    return "comp_stats_chart";
  } catch {
    if (container_type === "parent") {
      return "company_stats_chart_child_div";
    }
    return "comp_stats_chart";
  }
};

export const handle_show_maximize_minimize_btn = (
  categories,
  chart_type,
  chart_item,
) => {
  try {
    const user_name = localStorage.getItem("User");
    const get_localstorage_product_obj =
      localStorage.getItem("store_dashboard_stats") &&
      JSON.parse(localStorage.getItem("store_dashboard_stats"));
    if (get_localstorage_product_obj) {
      if (
        get_localstorage_product_obj?.["dashboard_stats"]?.[user_name]?.[
          chart_type
        ]?.[chart_item] !== undefined
      ) {
        const get_stats =
          get_localstorage_product_obj["dashboard_stats"][user_name][
            chart_type
          ][chart_item];
        return get_stats === "max" ? "minimize" : "maximize";
      }
    }
    // const categories_keys = Object.keys(categories[0]);
    // const x_axis_len = categories.length;
    // if (x_axis_len >= 20) return "minimize";
    // if (categories_keys.length >= 12) {
    //   return "minimize";
    // }
    return "maximize";
  } catch {
    return "maximize";
  }
};

export const handle_detect_company_pref_id = (
  chart_name,
  chart,
  company_pref_data,
  set_company_pref_id,
) => {
  try {
    const filter_company_pref = company_pref_data.filter((item) => {
      if (chart === "product" && item.preference_type === "product") {
        return chart_name === item.preference_value;
      }
      if (chart === "domain" && item.preference_type === "domain") {
        return chart_name === item.preference_value;
      }
      if (chart === "channel" && item.preference_type === "channel") {
        return chart_name === item.preference_value;
      }
      return chart_name === item.preference_value;
    });
    set_company_pref_id(filter_company_pref[0].id);
    return true;
  } catch {
    return true;
  }
};

export function compareDates(date1, date2) {
  const dateString = date1;
  // Split the date string into its components

  try {
    const [year, month, day] = dateString.split("-");
    if (year && month && day) {
      const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
      const oneWeek = 7 * oneDay; // One week in milliseconds
      const date1Obj = new Date(date1);
      const date2Obj = new Date(date2);
      const difference = Math.abs(date2Obj - date1Obj);
      if (difference === oneDay) {
        return "Daily";
      }
      if (difference === oneWeek) {
        return "Weekly";
      }
      return "Daily";
    }
    if (year && month) {
      return "Monthly";
    }
    return "Hourly";
  } catch {
    return "Daily";
  }
}

export const handle_time_format = (date) => {
  if (date.includes("T")) {
    const dateTime = new Date(date);
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }
  return date;
};

export const store_stats_localstorage = (
  chart_type,
  chart_item,
  chart_div_status,
) => {
  const user_name = localStorage.getItem("User");
  try {
    const get_localstorage =
      localStorage.getItem("store_dashboard_stats") &&
      JSON.parse(localStorage.getItem("store_dashboard_stats"));
    if (get_localstorage) {
      const get_localstorage_dashboard_stats = get_localstorage.dashboard_stats;
      if (
        Object.prototype.hasOwnProperty.call(
          get_localstorage_dashboard_stats,
          user_name,
        )
      ) {
        const clone_obj = get_localstorage_dashboard_stats[user_name];
        if (Object.prototype.hasOwnProperty.call(clone_obj, chart_type)) {
          const filter_clone_obj = clone_obj[chart_type];
          get_localstorage_dashboard_stats[user_name][chart_type] = {
            ...filter_clone_obj,
            [chart_item]: chart_div_status,
          };
        } else {
          get_localstorage_dashboard_stats[user_name] = {
            ...clone_obj,
            [chart_type]: { [chart_item]: chart_div_status },
          };
        }
      } else {
        get_localstorage_dashboard_stats[user_name] = {
          [chart_type]: { [chart_item]: chart_div_status },
        };
      }
      const store_obj = {
        dashboard_stats: { ...get_localstorage_dashboard_stats },
      };
      localStorage.setItem(`store_dashboard_stats`, JSON.stringify(store_obj));
    } else {
      const store_obj = {
        dashboard_stats: {
          [user_name]: {
            [chart_type]: {
              [chart_item]: chart_div_status,
            },
          },
        },
      };
      localStorage.setItem(`store_dashboard_stats`, JSON.stringify(store_obj));
    }
  } catch {
    const store_obj = {
      dashboard_stats: {
        [user_name]: {
          [chart_type]: {
            [chart_item]: chart_div_status,
          },
        },
      },
    };
    localStorage.setItem(`store_dashboard_stats`, JSON.stringify(store_obj));
  }
};

export const handle_delete_stats_localstorage = (chart_type, chart_item) => {
  const user_name = localStorage.getItem("User");
  try {
    const get_localstorage_product_obj =
      localStorage.getItem("store_dashboard_stats") &&
      JSON.parse(localStorage.getItem("store_dashboard_stats"));
    if (get_localstorage_product_obj) {
      if (
        get_localstorage_product_obj?.["dashboard_stats"]?.[user_name]?.[
          chart_type
        ]?.[chart_item] !== undefined
      ) {
        delete get_localstorage_product_obj["dashboard_stats"][user_name][
          chart_type
        ][chart_item];
        setTimeout(() => {
          localStorage.setItem(
            `store_dashboard_stats`,
            JSON.stringify(get_localstorage_product_obj),
          );
        });
      }
    }
  } catch {
    // Do nothing
  }
};

export const file_download = (id, file_type, getSearchedData) => {
  let token = localStorage.getItem("Token");
  const data = { file_hash: id, file_type: file_type };
  try {
    axios
      .post("https://dev.recordedtelegram.com/stealer_logs_download", data, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const new_file_name = `${getSearchedData}_${new Date()}.${file_type}`;
        fileDownload(res.data, new_file_name);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(
          err.response.data,
          err.response.status,
        );
        if (token_expire_status === true) return;
        let errorMessage = "";
        const reader = new FileReader();
        reader.onload = () => {
          errorMessage = reader.result;
          handle_error_message(JSON.parse(errorMessage).errormsg);
        };
        reader.readAsText(err.response.data);
      });
  } catch (err) {
    handle_error_message("Sorry could not download file");
  }
};

export const handle_determine_graph_or_table_data = (
  chart_data,
  display_type,
  chart_filter_type,
) => {
  if (display_type === "graph") {
    const categories_obj = chart_data;
    if (chart_filter_type === "keyword") {
      const restructuredData = (
        Object.keys(categories_obj).length > 0
          ? categories_obj[Object.keys(categories_obj)[0]]
          : []
      ).map((item, index) => {
        const newObj = {
          name: handle_time_format(item.date),
        };

        for (const val in categories_obj) {
          newObj[val] = categories_obj[val][index].value;
        }
        return newObj;
      });

      const categories_channel_obj = {};
      for (const val in categories_obj) {
        categories_channel_obj[val] = categories_obj[val].map(
          (item) => item.channel,
        );
      }
      return {
        sanitize_data: restructuredData,
        sanitize_channel: categories_channel_obj,
      };
      // set_chart_stats((prev) => ({
      //   ...prev,
      //   [alert_type]: {
      //     ...prev[alert_type],
      //     [stats_name]: {
      //       ...prev[alert_type][stats_name],
      //       loading_chart: false,
      //       error: "",
      //       data: restructuredData,
      //       categories_channel: categories_channel_obj,
      //     },
      //   },
      // }));
    } else if (chart_filter_type === "tag") {
      const filter_array =
        Object.keys(categories_obj).length > 0
          ? categories_obj[Object.keys(categories_obj)[0]]
          : [];
      const restructuredData = filter_array.map((item, index) => {
        const newObj = {
          name: handle_time_format(item.date),
        };
        for (const val in categories_obj) {
          newObj[val] = categories_obj[val][index].value;
        }
        return newObj;
      });

      const categories_channel_arr = filter_array.map((item, index) => {
        const newObj = {};
        for (const val in categories_obj) {
          newObj[val] = {
            channel_name: val,
            total_messages: categories_obj[val][index].messages.length,
            messages: categories_obj[val][index].messages,
            date: item.date,
          };
        }
        return newObj;
      });
      return {
        sanitize_data: restructuredData,
        sanitize_channel: categories_channel_arr,
      };
      // set_chart_stats((prev) => ({
      //   ...prev,
      //   [alert_type]: {
      //     ...prev[alert_type],
      //     [stats_name]: {
      //       ...prev[alert_type][stats_name],
      //       loading_chart: false,
      //       error: "",
      //       data: restructuredData,
      //       categories_channel: categories_channel_arr,
      //     },
      //   },
      // }));
    }
  } else {
    return {
      sanitize_data: chart_data,
      sanitize_channel: [],
    };
  }
};
