import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import TagSearchTable from "./TagSearchTable";
import JsonEditor from "../display_json/JsonEditor";
import "react-toastify/dist/ReactToastify.css";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_check_category_length } from "../../../Assets/globalfunctions";
import axios from "axios";
/*
NOTE : This component is used to make the request for the Tag search which includes Bank card, 
email, ip address,crypto, domain, phone,bin
*/
function TagSearchFunction({ tag_search_ref }) {
  const [tag_search_data, set_tag_search_data] = useState([]);
  const [error_status, set_error_status] = useState(false);
  const [tag_search_error, set_tag_search_error] = useState();
  const [show_table, set_show_table] = useState(false);
  const [loadmore_loading, set_loadmore_loading] = useState(false);
  const [getSearchedData, setGetSearchedData] = useState();
  const [template_card_filters, set_template_card_filters] =
    useState("bank_card");
  const [tempate_display, set_template_display] = useState("table");
  const [total_data, set_total_data] = useState();
  const [search_after_id, set_search_after_id] = useState(null);
  const [tag_rate_limit, set_tag_rate_limit] = useState();
  const [file_id, set_file_id] = useState();

  useEffect(() => {
    tag_search_ref.current = get_tag_data;
  }, []);

  let isSubmitBlocked = false;
  const get_tag_data = useCallback(
    (
      searchedData,
      card_filters,
      exp_year,
      exp_month,
      card_type,
      crypto_provider,
      email_provider,
      card_date_from,
      card_date_to,
      max_tag,
      tag_source,
      tag_filter_type,
      channels_list_tag,
      display_type_tag,
      post_category_tag,
    ) => {
      if (show_table === false && tag_search_data.length === 0) {
        document.getElementById("search_loading_modal").className =
          "modal fade show";
        document.getElementById("search_loading_modal").style.display = "block";
        set_show_table(false);
      } else if (show_table === true && tag_search_data.length > 0) {
        set_loadmore_loading(true);
      }
      setGetSearchedData(searchedData);
      set_template_card_filters(card_filters);
      set_template_display(display_type_tag);
      const token = localStorage.getItem("Token");
      const data = {
        search_mode: card_filters,
        qtext: searchedData,
        expiry_year:
          card_filters === "bank_card" || card_filters === "bin"
            ? exp_year.length === 11
              ? "all"
              : exp_year
            : null,
        expiry_month:
          card_filters === "bank_card" || card_filters === "bin"
            ? exp_month.length === 12
              ? "all"
              : exp_month
            : null,
        bank_card_type:
          card_filters === "bank_card" || card_filters === "bin"
            ? card_type.length === 15
              ? "all"
              : card_type
            : null,
        email_provider:
          card_filters === "email"
            ? email_provider.length === 9
              ? "all"
              : email_provider
            : null,
        crypto_type:
          card_filters === "crypto"
            ? crypto_provider.length === 5
              ? "all"
              : crypto_provider
            : null,
        start_date: card_date_from,
        end_date: card_date_to,
        max_result: Number(max_tag),
        search_after_id: search_after_id !== "None" ? search_after_id : null,
        source:
          tag_filter_type === "individual" ||
          (card_filters !== "bank_card" && card_filters !== "bin")
            ? "telegram"
            : tag_source.length === 2
            ? "all"
            : tag_source.length === 0
            ? "telegram"
            : tag_source[tag_source.length - 1],
        channel_name:
          tag_filter_type === "individual" ? channels_list_tag : "None",
        search_type: tag_filter_type === "individual" ? "channel" : "default",
        selectCategory:
          handle_check_category_length(post_category_tag) === true
            ? "all"
            : post_category_tag,
      };
      if (!isSubmitBlocked) {
        isSubmitBlocked = true;
        axios
          .post("https://dev.recordedtelegram.com/v2/tag_search", data, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            const all_data = res.data.data;
            const delete_post_hash_id = all_data.map((item) => {
              if ("post_hash_id" in item) {
                delete item.post_hash_id;
                return item;
              } else {
                return item;
              }
            });
            set_show_table(true);
            set_tag_search_data([...tag_search_data, ...delete_post_hash_id]);
            set_total_data(res.data.total_data);
            set_search_after_id(res.data.search_id);
            set_tag_rate_limit(res.data.ratelimit);
            set_file_id(res.data.file_id);
            set_error_status(false);
            set_loadmore_loading(false);
            document.getElementById("search_loading_modal").className =
              "modal fade";
            document.getElementById("search_loading_modal").style.display =
              "none";
            isSubmitBlocked = false;
          })
          .catch((err) => {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            set_error_status(true);
            set_show_table(true);
            set_tag_search_error(err.response.data.errormsg);
            set_loadmore_loading(false);
            document.getElementById("search_loading_modal").className =
              "modal fade";
            document.getElementById("search_loading_modal").style.display =
              "none";
            isSubmitBlocked = false;
          });
      }
    },
    [tag_search_data, getSearchedData, search_after_id],
  );
  return (
    <div>
      {/* Lodading modal animation */}
      <div
        className="modal fade"
        id="search_loading_modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="search_loading_modal"
        aria-hidden="true"
        style={{ background: "rgba(0, 0, 0, 0.5)" }}
      >
        <div
          className="modal-dialog modal-confirm"
          style={{ background: "#2b3c46", marginTop: "13rem" }}
        >
          <div className="modal-content dk-mode">
            <div className="modal-header flex-column">
              <h4
                className="modal-title w-100"
                id="model_title"
                style={{ fontSize: "18px", color: "#359daf" }}
              >
                Please wait
              </h4>
            </div>
            <div className="modal-body" style={{ marginTop: "50px" }}>
              <div className="load-wrapper" style={{ marginLeft: "200px" }}>
                <div
                  className="load-circle"
                  style={{ background: "#359daf" }}
                />
                <div
                  className="load-circle"
                  style={{ background: "#359daf" }}
                />
                <div className="load-circle" style={{ background: "#359daf" }}>
                  {" "}
                </div>
                <div className="load-shadow" />
                <div className="load-shadow" />
                <div className="load-shadow" />
                <span style={{ left: "2%", color: "#359daf" }}>
                  Searching...
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show_table === true ? (
        error_status === true ? (
          <div className="main__table no_data">{tag_search_error}</div>
        ) : tag_search_data.includes(
            "No results. Please try again after some time.",
          ) ? (
          <div className="main__table no_data">No data to display</div>
        ) : tag_search_data.length === 0 ? (
          <div className="main__table no_data">No data to display</div>
        ) : tempate_display === "table" ? (
          <TagSearchTable
            Data={tag_search_data}
            getSearchedData={getSearchedData}
            template_card_filters={template_card_filters}
            get_tag_data={get_tag_data}
            total_data={total_data}
            tag_rate_limit={tag_rate_limit}
            file_id={file_id}
            loadmore_loading={loadmore_loading}
          />
        ) : (
          <JsonEditor
            Data={tag_search_data}
            getSearchedData={getSearchedData}
            file_id={file_id}
            total_data={total_data}
            tag_rate_limit={tag_rate_limit}
            json_display_type="tag_search"
          />
        )
      ) : null}
      <ToastContainer />
    </div>
  );
}
TagSearchFunction.propTypes = {
  tag_search_ref: PropTypes.object,
};

export default TagSearchFunction;
